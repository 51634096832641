<template>
  <div class="uk-card uk-card-default kandang-card">
    <!-- <loading-card v-if="isLoading" /> -->
    <div
        
      class="uk-child-width-expand@s uk-grid-small"
      uk-grid
    >
      <!-- Section Card Info Kandang -->

      <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
        <h1 class="text-medium">
          Informasi Kandang
        </h1>
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-3@l uk-width-1-3@xl uk-margin-auto-vertical">
            Kode Kandang
          </div>
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl uk-margin-remove-top">
            <input
              v-model="formData.cage_code"
              class="uk-input"
              type="text"
              name="cage_code"
              disabled
            >
          </div>
        </div>
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-3@l uk-width-1-3@xl uk-margin-auto-vertical">
            Nama Kandang
          </div>
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl uk-margin-remove-top">
            <input
              v-model="formData.cage_name"
              class="uk-input"
              type="text"
              disabled
            >
          </div>
        </div>
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-3@l uk-width-1-3@xl uk-margin-auto-vertical">
            Peternakan
          </div>
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl uk-margin-remove-top">
            <input
              v-model="formData.farm"
              class="uk-input"
              type="text"
              disabled
            >
          </div>
        </div>
      </div>

      <!-- Section Card Laba -->

      <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl card-laba">
        <!-- Section Card Laba Hasil Produksi -->

        <div class="uk-margin-bottom">
          <h1 class="text-medium">
            Perhitungan Dana Jaminan
          </h1>
          <div class="uk-margin-top wrapper-content">
            <div
              class="uk-child-width-expand@s uk-grid-small wrapper-field"
              uk-grid
            >
              <div 
                class="label-red uk-width-1-2@s uk-width-1-2@m uk-width-1-2@l uk-width-1-2@xl field-style"
              >
                Total Tagihan Dana Jaminan
              </div>
              <div class="uk-width-1-2@s uk-width-1-2@m uk-width-1-2@l uk-width-1-2@xl set-style">
                <div 
                  class="label-red uk-input currency-field uk-text-bold"
                >
                  <span class="uk-margin-large-left uk-width-1-2">Rp</span>
                  <span
                    class="uk-width-auto uk-input uk-text-bold label-red uk-float-right uk-text-right"
                  >
                    {{ thousandSeparator(formData.installment_amount) }}
                  </span>
                </div>
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small wrapper-field"
              uk-grid
            >
              <div class="uk-width-1-2@s uk-width-1-2@m uk-width-1-2@l uk-width-1-2@xl field-style">
                Uang Muka
              </div>
              <div class="uk-width-1-2@s uk-width-1-2@m uk-width-1-2@l uk-width-1-2@xl set-style">
                <div class="uk-input currency-field uk-text-bold">
                  <span class="uk-margin-large-left uk-width-1-2">Rp</span>
                  <span
                    class="uk-width-auto uk-input uk-text-bold label-green uk-float-right uk-text-right"
                  >
                    {{ thousandSeparator(formData.down_payment) }}
                  </span>
                </div>
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small wrapper-field"
              uk-grid
            >
              <div class="uk-width-1-2@s uk-width-1-2@m uk-width-1-2@l uk-width-1-2@xl field-style">
                Total Pembayaran Cicilan RHPP
              </div>
              <div class="uk-width-1-2@s uk-width-1-2@m uk-width-1-2@l uk-width-1-2@xl uk-margin-remove-top set-style">
                <div class="uk-input uk-text-bold currency-field">
                  <span class="uk-margin-large-left uk-width-1-2">Rp</span>
                  <span
                    class="uk-width-auto uk-input uk-text-bold label-green uk-float-right uk-text-right"
                  >
                    {{ thousandSeparator(formData.total_installment_payment_of_rhpp) }}
                  </span>
                </div>
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small bottom-field"
              uk-grid
            >
              <div
                :class="formData.rest_of_installment == 0 ? 'label-green' : 'label-red'"
                class="uk-width-1-2@s uk-width-1-2@m uk-width-1-2@l uk-width-1-2@xl field-style"
              >
                Sisa Cicilan Dana Jaminan
              </div>
              <div class="uk-width-1-2@s uk-width-1-2@m uk-width-1-2@l uk-width-1-2@xl set-style">
                <div
                  :class="formData.rest_of_installment == 0 ? 'label-green' : 'label-red'"
                  class="uk-input label-red uk-text-bold"
                >
                  <span class="uk-width-1-2 uk-margin-large-left">{{ formData.rest_of_installment == 0 ? 'Rp' : '-Rp' }}</span>
                  <span
                    class="uk-width-auto uk-input uk-text-bold label-red uk-float-right uk-text-right"
                    :class="formData.rest_of_installment == 0 ? 'label-green' : 'label-red'"
                  >
                    {{ thousandSeparator(formData.rest_of_installment) }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="showHistory"
        class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-1@l uk-width-1-1@xl"
      >
        <!-- Section Card Laba Hasil Produksi -->

        <div class="uk-margin-bottom">
          <h1 class="text-medium">
            Riwayat Pembayaran Cicilan Dana Jaminan
          </h1>
          <div class="uk-margin-top">
            <div class="uk-overflow-auto border-table">
              <table class="uk-table uk-table-striped uk-table-hover uk-table-divider">
                <thead class="thead-table-paranje">
                  <tr>
                    <th class="uk-table-expand">
                      <span class="uk-text-bold">Siklus Ke</span>
                    </th>
                    <th class="uk-table-expand">
                      <span class="uk-text-bold">Kode Siklus atau RHPP</span>
                    </th>
                    <th class="uk-table-expand">
                      <span class="uk-text-bold">Tanggal Pembayaran</span>
                    </th>
                    <th class="uk-table-expand">
                      <span class="uk-text-bold">Pembayaran Cicilan</span>
                    </th>
                  </tr>
                </thead>
                <template v-if="formData.installment_histories.length>0">
                  <tr
                    v-for="(item, i) in formData.installment_histories"
                    :key="i"
                  >
                    <td>
                      {{ item.cycle_count }}
                    </td>
                    <td>
                      {{ item.cycle_code }}
                    </td>
                    <td>
                      {{ item.payment_date }}
                    </td>
                    <td>
                      {{ currency(item.installment_payment) }}
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <empty-table :colspan="4" />
                </template> 
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { PREFIX_IMAGE } from '@/utils/constant'
import { mapActions, mapGetters } from 'vuex'
import EmptyTable from "@/components/globals/table/EmptyTable"
import { currency, encodeStatusMitra, thousandSeparator } from '@/utils/formater'
  
export default {
  components: {
    EmptyTable
  },
  data() {
    return {
      images: PREFIX_IMAGE,
      isDetail: true,
      formData: {},
      available: 1,
      notAvailable: 0,
      showHistory: false
    }
  },
  computed : {
    ...mapGetters({
      dataDetail: 'acquisition/dataDetail',
      dataGuaranteeFund: 'acquisition/getGuaranteeFund'
    })
  },
  watch: {
    dataDetail(){
      const historyView = [encodeStatusMitra(10), encodeStatusMitra(11), encodeStatusMitra(12)]
      this.showHistory = historyView.includes(this.dataDetail.status_acquisition)
    }
  },
  async mounted(){
    await this.getGuaranteeFund(this.$route.params.id)
    this.formData = this.dataGuaranteeFund ? this.dataGuaranteeFund : {}
  },
  methods: {
    thousandSeparator(s){
      return thousandSeparator(s)
    },
    ...mapActions({
      getDataDetail: 'acquisition/getDataDetail',
      getGuaranteeFund: 'acquisition/fetchingGuaranteeFund'
    }),
    encodeStatusMitra(s){
      return encodeStatusMitra(s)
    },
    currency(s){
      return currency(s)
    },
    handleSubmit() {
      if (this.isDetail) {
        this.isDetail = false
      } else {
        this.$validator.validateAll().then(async (success) => {
          if (success) {
            if (this.$validator.errors.any()) return
            await this.updateFacility({
              ...this.formData,
              partner_assistant_name: this.formData.partner_assistant ? this.formData.partner_assistant_name : ''
            })
            this.getDataDetail(this.$route.params.id)
            this.getDataDetailStatus(this.$route.params.id)
            this.isDetail = true
          }
        })
      }
    }
  }
}
</script>
  
<style scoped>
h1 {
  color: #1F2E28;
  font-size: 14px;
}

.card-laba .wrapper-content {
  border: 1px solid #C2D6CE;
  box-sizing: border-box;
  border-radius: 16px;
  overflow: hidden;
}

.card-laba .field-style {
  display: flex;
  align-items: center;
  padding-left: 16px;
  color: #1F2E28;
  font-size: 14px !important;
}

.card-laba .uk-input {
  color: #1F2E28;
  border: none;
  background: none;
}

.currency-field {
  overflow: hidden;
  align-items: center;
  color: #1F2E28;
}

.wrapper-field {
  height: 58px !important;
  margin-top: 0px;
  margin-left: 0;
  border-bottom: 1px solid #c2d6ce73;
}

.bottom-field {
  height: 58px !important;
  margin-top: 0px;
  margin-left: 0;
  background: #F0F5F3;
}

.set-style {
  display: flex;
  align-items: center;
}

.set-bottom-style {
  color: #025231;
}
</style>
