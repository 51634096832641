<template>
  <div
    class="uk-child-width-expand@s uk-grid-small"
    uk-grid
  >
    <div class="uk-width-1-1 uk-text-left">
      <strong>
        Unggah Kuitansi Dana Jaminan
      </strong>
    </div>
    <div class="uk-width-1-1">
      <div
        class="uk-child-width-expand@s uk-grid-small"
        uk-grid
      >
        <div class="label-green uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical uk-text-left">
          Nominal Pembayaran
        </div>
        <div
          class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl js-upload"
          uk-form-custom
        >
          <div class="uk-inline">
            <span class="uk-form-icon">Rp </span>
            <input
              v-model="nominalPayment"
              v-mask="currencyMask"
              value="nominalPayment"
              class="uk-input"
              type="numeric"
              name="document_number"
              placeholder="Masukkan Nominal"
            >
          </div>
        </div>
      </div>
      <div
        class="uk-child-width-expand@s uk-grid-small"
        uk-grid
      >
        <div class="label-green uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical uk-text-left">
          Kuitansi Dana Jaminan
        </div>
        <div
          class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl js-upload"
          uk-form-custom
        >
          <input
            ref="fileupload"
            v-validate="{ 'required':true }"
            name="receipt_file"
            class="{'uk-form-danger': errors.has('receipt_file')}"
            accept="image/png,image/jpg,image/jpeg,application/pdf"
            type="file"
            multiple
            @change="(event) => handleUploadFile(event, 'receipt_file')"
          >
          <div class="uk-inline">
            <img
              v-lazy="`${images}/icon/logout.svg`"
              alt="icon upload"
              class="uk-form-icon uk-form-icon-flip icon-upload"
            >
            <div class="uk-input uk-text-left">
              <span class="uk-panel uk-text-truncate">
                {{ formData.receipt_file ? formData.receipt_file.name : 'Unggah Dokumen' }}
              </span>
            </div>
          </div>
          <div
            v-show="errors.has('receipt_file')"
            class="uk-text-small uk-text-danger"
          >
            {{ !formData.receipt_file ? 'Mohon masukkan File Bukti Pembayaran' : errors.first('receipt_file') }}
          </div>
        </div>
      </div>
    </div>
    <div class="uk-width-1-1 uk-text-right uk-margin-top">
      <button
        class="uk-button uk-button-primary uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
        type="button"
        :disabled="isLoading"
        @click="handleSubmit"
      >
        <span
          v-if="isLoading"
          uk-spinner
          class="button-spinner uk-text-muted"
        />
        <span v-else>
          <img
            v-lazy="`${images}/icon/${'save' }.svg`"
            alt="icon action"
            class="uk-margin-small-right"
          >
        </span>
        Simpan
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { PREFIX_IMAGE } from '@/utils/constant'
import { compressedImage, currency } from '@/utils/formater'
import { stringNotifDanger, notificationSuccess } from '@/utils/notification'
import createNumberMask from "text-mask-addons/dist/createNumberMask"


export default {
  props: {
    dataReceipt: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      isLoading: false,
      formData: {
        id: this.$route.params.id
      },
      nominalPayment:'',
      currencyMask: createNumberMask({
        prefix: "",
        includeThousandsSeparator: true,
        allowNegative: false,
        thousandsSeparatorSymbol: "."
      }),
      images: PREFIX_IMAGE
    }
  },
  computed : {
    ...mapGetters({
      dataListInstallment: 'acquisition/dataListInstallment'
    })
  },
  async mounted(){
    await this.fetchingListInstallment({
      acquisition_id: this.$route.params.id
    })
    if (this.dataListInstallment && this.dataListInstallment.data) {
      let highestTermin = 0
      this.dataListInstallment.data.forEach(itemInstalment => {
        if (itemInstalment.termin && itemInstalment.termin > highestTermin) {
          highestTermin = itemInstalment.termin
          this.formData.id = itemInstalment.id
          this.nominalPayment = currency(itemInstalment.amount)
        }
      })
    }
  },
  methods: {
    ...mapActions({
      fetchingReceipt: 'acquisition/fetchingReceipt',
      getDataDetail: 'acquisition/getDataDetail',
      getDataDetailStatus: 'acquisition/getDataDetailStatus',
      fetchingListInstallment: 'acquisition/fetchingListInstallment'
    }),
    async handleUploadFile(data, target) {
      const file = data.target.files[0]
      const fileType = file.type
      const fileSize = file.size
      const maxFileSize = 1048576
      const allowedTypes = ["application/pdf", "image/jpeg", "image/png", "image/gif"]
      const errorWordingDefault = 'File Bukti Pembayaran tidak melebihi 1mb dengan format JPEG, JPG dan PNG”'
      const errorWordingSize = 'File Bukti Pembayaran tidak melebihi 1mb'
      if (file) {
        if (allowedTypes.includes(fileType)) {
          if(file.type != allowedTypes[0]){
            const compressedFile = await compressedImage(file, 1)
            this.formData = {
              ...this.formData,
              [target]: {
                data: compressedFile,
                name: compressedFile.name
              }
            }
          } else {
            if(fileSize > maxFileSize){
              stringNotifDanger(errorWordingSize)
              this.$refs.fileupload.value = null
            } else {
              this.formData = {
                ...this.formData,
                [target]: {
                  data: file,
                  name: file.name
                }
              }
            }
          }
        } else {
          stringNotifDanger(errorWordingDefault)
          this.$refs.fileupload.value = null
        }
      }
    },
    async handleSubmit() {
      this.$validator.validateAll().then(async (success) => {
        if (success) {
          if (this.$validator.errors.any()) return
          this.isLoading = true
          let plainNominal = this.nominalPayment.replace(/\./g, '')
          const formData = new FormData()
          formData.append('receipt_file', this.formData.receipt_file.data)
          formData.append('id', this.formData.id)
          formData.append('amount', plainNominal)
          await this.fetchingReceipt(formData)
          const res = await this.$store.getters['acquisition/getResponseRecipt']
          if(res.toLowerCase() == 'ok'){
            notificationSuccess('Berhasil Upload Recipt')
            this.isLoading = false
            setTimeout(() => {
              window.location.reload()
            }, 700)
          } else {
            this.isLoading = false
          }
        }
      })
    }
  }
}
</script>

<style scoped>
  .icon-upload {
    padding: 8px;
  }

  strong {
    font-weight: 600;
    font-size: 15px;
    line-height: 150%;
    color: #1F2E28
  }
</style>
