<template>
  <div>
    <h4 class="uk-width-1-1 uk-text-left">
      <strong>
        {{ statusProspect == "interest" ? "Pilih Tanggal Presentasi" : "Mitra Tidak Tertarik" }}
      </strong>
    </h4>
    <div class="uk-form-horizontal">
      <!-- section if not interested -->
      <div
        v-if="statusProspect != 'interest'" 
        class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m"
      >
        <label
          class="uk-form-label uk-text-left label-green"
        >
          Pilih Alasan
        </label>
        <div class="uk-form-controls">
          <multiselect
            id="reason_not_interested"
            v-model="formPartnerProspect.reason"
            v-validate="'required'"
            name="reason"
            placeholder="Pilih Alasan"
            open-direction="bottom"
            :options="listReason"
            :searchable="true"
            :close-on-select="true"
            :max-height="500"
            :show-no-results="true"
            :loading="isLoading"
          >
            <span slot="noResult">Oops! Data tidak ditemukan.</span>
          </multiselect>
          <div
            v-show="errors.has('reason')"
            class="uk-text-small uk-text-danger"
          >
            {{ errors.first("reason") }}
          </div>
        </div>
        <div
          v-if="formPartnerProspect.reason == 'Lainnya'"
          class="uk-form-controls uk-margin-top"
        >
          <input
            id="other_reason"
            v-model="formPartnerProspect.other_reason"
            v-validate="{ required: true }"
            class="uk-input"
            name="other_reason"
            type="text"
            placeholder="Masukan Alasan Lainnya"
            :class="{ 'uk-form-danger': errors.has('other_reason'), 'uk-input': true }"
          >
          <div
            v-show="errors.has('other_reason')"
            class="uk-text-small uk-text-danger"
          >
            {{ errors.first("other_reason") }}
          </div>
        </div>
      </div>
      <!-- end section if not interested -->

      <!-- date section -->
      <div class="uk-width-1-1 uk-margin-small-top">
        <label
          class="uk-form-label uk-text-left label-green"
        >
          Tanggal Presentasi
        </label>
        <div
          class="uk-form-controls uk-flex uk-flex-middle uk-padding-left"
          uk-grid
        >
          <div class="uk-width-expand uk-padding-remove">
            <datepicker
              ref="present_start_date"
              v-model="formPartnerProspect.present_start_date"
              v-validate="'required'"
              name="present_start_date"
              input-class="uk-input"
              placeholder="dd/mm/yyyy"
              :disabled-dates="startDisabled"
              :class="{ 'uk-form-danger': errors.has('present_start_date') }"
              @input="disablerDates()"
            />
            <div
              v-show="errors.has('present_start_date')"
              class="uk-text-small uk-text-danger"
            >
              {{ errors.first("present_start_date") }}
            </div>
          </div>
          <div class="uk-width-1-5 uk-padding-remove">
            to
          </div>
          <div class="uk-width-expand uk-padding-remove">
            <datepicker
              ref="present_end_date"
              v-model="formPartnerProspect.present_end_date"
              v-validate="'required'"
              name="present_end_date"
              input-class="uk-input"
              placeholder="dd/mm/yyyy"
              :disabled-dates="endDisabled"
              :class="{ 'uk-form-danger': errors.has('present_end_date') }"
            />
            <div
              v-show="errors.has('present_end_date')"
              class="uk-text-small uk-text-danger"
            >
              {{ errors.first("present_end_date") }}
            </div>
          </div>
        </div>
      </div>
      <!-- end date section -->

      <!-- section if interested -->
      <div 
        v-if="statusProspect == 'interest'"
        class="uk-width-1-1 uk-margin-small-top"
      >
        <label
          class="uk-form-label uk-text-left label-green"
        >
          Rencana Survei Lokasi
        </label>
        <div
          class="uk-form-controls uk-flex uk-flex-middle"
          uk-grid
        >
          <div
            class="uk-padding-remove uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-1@l uk-width-1-1@xl"
          >
            <datepicker
              ref="site_survey_plan_date"
              v-model="formPartnerProspect.site_survey_plan_date"
              v-validate="'required'"
              name="site_survey_plan_date"
              input-class="uk-input"
              placeholder="dd/mm/yyyy"
              :disabled="!formPartnerProspect.present_end_date"
              :disabled-dates="surveyDisabled"
              :class="{ 'uk-form-danger': errors.has('site_survey_plan_date') }"
            />
            <div
              v-show="errors.has('site_survey_plan_date')"
              class="uk-text-small uk-text-danger"
            >
              {{ errors.first("site_survey_plan_date") }}
            </div>
          </div>
        </div>
      </div>
      <!-- end section if interested -->

      <div class="uk-width-1-1 uk-text-right uk-margin-top">
        <button
          class="uk-button uk-button-primary uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
          type="button"
          @click="() => handleSubmit(statusProspect)"
        >
          Simpan
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import { dateString, encodeStatusMitra } from "@/utils/formater"
import Datepicker from "vuejs-datepicker"


export default {
  components: {
    Datepicker
  },
  props: {
    statusProspect: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      formPartnerProspect: {},
      listReason: [],
      isLoading: true,
      startDisabled: {},
      endDisabled: {
        to: ""
      },
      surveyDisabled: {
        from: "",
        to: ""
      }
    }
  },
  computed: {
    ...mapGetters({
      listsNotInterested: "acquisition/listsNotInterested",
      dataDetail: "acquisition/dataDetail",
      dataProspect: "acquisition/dataProspect"
    })
  },
  watch: {
    statusProspect(after, before){
      if(before != after){
        this.formPartnerProspect.present_start_date = ''
        this.formPartnerProspect.present_end_date = ''
      }
    },
    "formPartnerProspect.present_start_date"() {
      this.formPartnerProspect.present_start_date = this.dateFormatFilter(this.formPartnerProspect.present_start_date)
    },
    "formPartnerProspect.present_end_date"() {
      this.formPartnerProspect.present_end_date = this.dateFormatFilter(this.formPartnerProspect.present_end_date)
      this.surveyDisabled.to = new Date(this.formPartnerProspect.present_end_date)
    },
    "formPartnerProspect.site_survey_plan_date"() {
      this.formPartnerProspect.site_survey_plan_date = this.dateFormatFilter(this.formPartnerProspect.site_survey_plan_date)
    }
  },
  async mounted(){
    await this.getlistsNotInterested()
    if(this.listsNotInterested){
      this.listReason = this.listsNotInterested
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getlistsNotInterested: "acquisition/getListsNotInterested",
      getPartnerProspect: "acquisition/getPartnerProspect",
      getDataDetail: "acquisition/getDataDetail"
    }),
    dateFormatFilter(date) {
      return dateString(date)
    },
    disablerDates() {
      if(new Date(new Date(this.formPartnerProspect.present_start_date).getTime()) > new Date(new Date(this.formPartnerProspect.present_end_date).getTime())){
        this.formPartnerProspect.present_end_date = ''
      }
      if (this.formPartnerProspect.present_start_date != "") {
        this.endDisabled.to = new Date(new Date(this.formPartnerProspect.present_start_date).getTime())
      } else {
        this.endDisabled.to = ""
      }
    },
    handleSubmit(field) {
      this.$validator.validateAll().then(async (success) => {
        if (success) {
          if (this.$validator.errors.any()) return
          if (field == "not_interest") {
            await this.getPartnerProspect({
              site_survey_plan_date: null,
              status_acquisition: this.encodeStatusMitra(1),
              id: this.dataDetail ? this.dataDetail.id : "",
              reason: this.formPartnerProspect.reason,
              other_reason: this.formPartnerProspect.other_reason,
              present_start_date: this.formPartnerProspect.present_start_date,
              present_end_date: this.formPartnerProspect.present_end_date
            })
          } else {
            await this.getPartnerProspect({
              status_acquisition: this.encodeStatusMitra(2),
              id: this.dataDetail ? this.dataDetail.id : "",
              present_start_date: this.formPartnerProspect.present_start_date,
              present_end_date: this.formPartnerProspect.present_end_date,
              site_survey_plan_date: this.formPartnerProspect.site_survey_plan_date
            })
          }
          if (this.dataProspect == 'OK') {
            this.getDataDetail(this.$route.params.id)
            window.UIkit.modal("#modal-standart").hide()
          }
        }
      })
    },
    encodeStatusMitra(number){
      return encodeStatusMitra(number)
    }
  }
}
</script>
