<template>
  <div
    class="uk-child-width-expand@s uk-grid-small"
    uk-grid
  >
    <div class="uk-width-1-1 uk-text-center">
      <h4>Verifikasi Dokumen</h4>
      <p>Apakah dokumen sudah sesuai?</p>
    </div>
    <div class="uk-width-1-1 uk-text-center uk-margin-top">
      <button
        class="uk-button uk-button-default uk-width-expand uk-width-auto@s"
        type="button"
        @click="() => handleSubmit('ignore')"
      >
        Batal
      </button>
      <button
        class="uk-button uk-button-primary uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
        type="button"
        @click="() => handleSubmit('verify')"
      >
        Ya, Verifikasi
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      payload: {
        id: this.$route.params.id,
        status_acquisition: 6
      }
    }
  },
  methods: {
    ...mapActions({
      fetchingStatusAcquisition: 'acquisition/fetchingStatusAcquisition',
      getDataDetail: 'acquisition/getDataDetail'
    }),
    async handleSubmit(field) {
      if (field == 'verify') {
        await this.fetchingStatusAcquisition(this.payload)
        this.getDataDetail(this.$route.params.id)
        window.UIkit.modal('#modal-standart').hide()
      }
      window.UIkit.modal('#modal-standart').hide()
    }
  }
}
</script>

<style scoped>
  h4 {
    font-weight: 700;
  }

  p {
    font-size: 14px;
  }
</style>
