<template>
  <div class="uk-margin-bottom">
    <div class="uk-card uk-card-default uk-padding-small kandang-card">
      <div
        class="uk-child-width-expand@s"
        uk-grid
      >
        <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl uk-margin-small-top">
          <div
            class="uk-child-width-expand@s"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Handphone
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <div 
                class="uk-margin uk-grid-small uk-child-width-auto uk-grid"
                uk-form-custom
              >
                <label>
                  <input
                    v-model="formData.handphone"
                    class="uk-radio"
                    type="radio"
                    name="handphone"
                    :value="available"
                    :disabled="!isEdit"
                  >
                  Tersedia
                </label>
                <label>
                  <input
                    v-model="formData.handphone"
                    class="uk-radio"
                    type="radio"
                    name="handphone"
                    :value="notAvailable"
                    :disabled="!isEdit"
                  >
                  Tidak Tersedia
                </label>
                <div
                  v-show="errors.has('handphone')"
                  class="uk-text-small uk-text-danger"
                >
                  {{ errors.first('handphone') }}
                </div>
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Genset
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <div
                class="uk-margin uk-grid-small uk-child-width-auto uk-grid"
                uk-form-custom
              >
                <label>
                  <input
                    v-model="formData.generator"
                    class="uk-radio"
                    type="radio"
                    name="generator"
                    :value="available"
                    :disabled="!isEdit"
                  >
                  Tersedia
                </label>
                <label>
                  <input
                    v-model="formData.generator"
                    class="uk-radio"
                    type="radio"
                    name="generator"
                    :value="notAvailable"
                    :disabled="!isEdit"
                  >
                  Tidak Tersedia
                </label>
                <div
                  v-show="errors.has('generator')"
                  class="uk-text-small uk-text-danger"
                >
                  {{ errors.first('generator') }}
                </div>
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Listrik<span class="uk-text-danger">*</span>
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <div
                class="uk-margin uk-grid-small uk-child-width-auto uk-grid"
                uk-form-custom
              >
                <label>
                  <input
                    v-model="formData.electricity"
                    v-validate="{ required: true }"
                    class="uk-radio"
                    type="radio"
                    name="electricity"
                    :value="available"
                    :disabled="!isEdit"
                  >
                  Tersedia
                </label>
                <label>
                  <input
                    v-model="formData.electricity"
                    v-validate="{ required: true }"
                    class="uk-radio"
                    type="radio"
                    name="electricity"
                    :value="notAvailable"
                    :disabled="!isEdit"
                  >
                  Tidak Tersedia
                </label>
                <div
                  v-show="errors.has('electricity')"
                  class="uk-text-small uk-text-danger"
                >
                  {{ errors.first('electricity') }}
                </div>
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Air<span class="uk-text-danger">*</span>
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <div
                class="uk-margin uk-grid-small uk-child-width-auto uk-grid"
                uk-form-custom
              >
                <label>
                  <input
                    v-model="formData.water"
                    v-validate="{ required: true }"
                    class="uk-radio"
                    type="radio"
                    name="water"
                    :value="available"
                    :disabled="!isEdit"
                  >
                  Tersedia
                </label>
                <label>
                  <input
                    v-model="formData.water"
                    v-validate="{ required: true }"
                    class="uk-radio"
                    type="radio"
                    name="water"
                    :value="notAvailable"
                    :disabled="!isEdit"
                  >
                  Tidak Tersedia
                </label>
                <div
                  v-show="errors.has('water')"
                  class="uk-text-small uk-text-danger"
                >
                  {{ errors.first('water') }}
                </div>
              </div>
            </div>
          </div>
          <!-- <div
            class="uk-child-width-expand@s"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Sarana Pendukung
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
                <template v-if="isDetail">
                  <div class="uk-input uk-width-1-1">
                    {{ formData.supporting_facilities ? 'Tersedia' : 'Tidak Tersedia' }}
                  </div>
                </template>
                <template v-else>
                  <label>
                    <input
                      v-model="formData.supporting_facilities"
                      v-validate="{ required: true }"
                      class="uk-radio"
                      type="radio"
                      name="supporting_facilities"
                      :value="available"
                    >
                    Tersedia
                  </label>
                  <label>
                    <input
                      v-model="formData.supporting_facilities"
                      v-validate="{ required: true }"
                      class="uk-radio"
                      type="radio"
                      name="supporting_facilities"
                      :value="notAvailable"
                    >
                    Tidak Tersedia
                  </label>
                  <div
                    v-show="errors.has('supporting_facilities')"
                    class="uk-text-small uk-text-danger"
                  >
                    {{ errors.first('supporting_facilities') }}
                  </div>
                </template>
              </div>
            </div>
          </div> -->
          <div
            class="uk-child-width-expand@s"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Asisten Mitra
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
                <label>
                  <input
                    v-model="formData.partner_assistant"
                    class="uk-radio"
                    type="radio"
                    name="partner_assistant"
                    :value="available"
                    :disabled="!isEdit"
                  >
                  Tersedia
                </label>
                <label>
                  <input
                    v-model="formData.partner_assistant"
                    class="uk-radio"
                    type="radio"
                    name="partner_assistant"
                    :value="notAvailable"
                    :disabled="!isEdit"
                  >
                  Tidak Tersedia
                </label>
                <div
                  v-show="errors.has('partner_assistant')"
                  class="uk-text-small uk-text-danger"
                >
                  {{ errors.first('partner_assistant') }}
                </div>
              </div>
            </div>
          </div>

          <template v-if="formData.partner_assistant == available">
            <div
              class="uk-child-width-expand@s"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Nama Asisten Mitra
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
                <div class="uk-margin uk-child-width-1-1">
                  <input
                    v-model="formData.partner_assistant_name"
                    v-validate="{ required: true }"
                    class="uk-input"
                    type="text"
                    name="partner_assistant_name"
                    placeholder="Masukan Nama Asisten Mitra"
                    :class="{'uk-form-danger': errors.has('partner_assistant_name')}"
                    :disabled="!isEdit"
                  >
                  <div
                    v-show="errors.has('partner_assistant_name')"
                    class="uk-text-small uk-text-danger"
                  >
                    {{ errors.first('partner_assistant_name') }}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Foto Lahan Yang Sudah Rata<span class="uk-text-danger">*</span>
            </div>
            <div
              class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl js-upload"
              uk-form-custom
            >
              <div
                v-if="isEdit"
              >
                <input
                  v-validate="{ 'required':false }"
                  name="flat_land"
                  class="{'uk-form-danger': errors.has('flat_land')}"
                  accept="image/png,image/jpg,image/jpeg,application/pdf"
                  type="file"
                  multiple
                  :disabled="!isEdit"
                  @change="(event) => handleUploadFile(event, 'flat_land')"
                >
                <div 
                  class="uk-inline"
                  tabindex="-1"
                >
                  <div
                    class="uk-input"
                  >
                    <span
                      style="max-width: 90%;"
                      class="uk-panel uk-text-truncate"
                    >
                      {{ formData.flat_land ? formData.flat_land.name : "Unggah Dokumen" }}
                    </span>
                    <img
                      v-lazy="formData.flat_land ? `${images}/icon/close-green.svg` : `${images}/icon/logout.svg`"
                      alt="icon action"
                      class="uk-form-icon uk-form-icon-flip icon-upload"
                      uk-icon
                    >
                  </div>
                  <div
                    v-show="errors.has('flat_land')"
                    class="uk-text-small uk-text-danger"
                  >
                    {{ errors.first('flat_land') }}
                  </div>
                </div>
              </div>
              <div v-else>
                <div
                  class="uk-width-1-1 uk-flex uk-flex-between"
                  tabindex="2"
                  :class="`${!isEdit ? 'uk-input-disabled' : 'uk-input'}`"
                >
                  <span class="uk-panel uk-text-truncate">
                    {{ formData.flat_land ? formData.flat_land.name :'-' }}
                  </span>
                  <img
                    v-if="formData.flat_land "
                    v-lazy="`${images}/icon/eye-line.svg`"
                    alt="icon action"
                    class="icon-upload"
                    @click="handlePreviewImage('flat_land')"
                  >
                </div>
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small uk-margin-top uk-margin-top"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Foto Sumber Listrik<span class="uk-text-danger">*</span>
            </div>
            <div
              class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl js-upload"
              uk-form-custom
            >
              <div
                v-if="isEdit"
              >
                <input
                  v-validate="{ 'required':false }"
                  name="electricity_source"
                  class="{'uk-form-danger': errors.has('electricity_source')}"
                  accept="image/png,image/jpg,image/jpeg,application/pdf"
                  type="file"
                  multiple
                  :disabled="!isEdit"
                  @change="(event) => handleUploadFile(event, 'electricity_source')"
                >
                <div 
                  class="uk-inline"
                  tabindex="-1"
                >
                  <div
                    class="uk-input"
                  >
                    <span
                      style="max-width: 90%;"
                      class="uk-panel uk-text-truncate"
                    >
                      {{ formData.electricity_source ? formData.electricity_source.name : "Unggah Dokumen" }}
                    </span>
                    <img
                      v-lazy="formData.electricity_source ? `${images}/icon/close-green.svg` : `${images}/icon/logout.svg`"
                      alt="icon action"
                      class="uk-form-icon uk-form-icon-flip icon-upload"
                      uk-icon
                    >
                  </div>
                  <div
                    v-show="errors.has('electricity_source')"
                    class="uk-text-small uk-text-danger"
                  >
                    {{ errors.first('electricity_source') }}
                  </div>
                </div>
              </div>
              <div v-else>
                <div
                  class="uk-width-1-1 uk-flex uk-flex-between"
                  tabindex="2"
                  :class="`${!isEdit ? 'uk-input-disabled' : 'uk-input'}`"
                >
                  <span class="uk-panel uk-text-truncate">
                    {{ formData.electricity_source ? formData.electricity_source.name :'-' }}
                  </span>
                  <img
                    v-if="formData.electricity_source "
                    v-lazy="`${images}/icon/eye-line.svg`"
                    alt="icon action"
                    class="icon-upload"
                    @click="handlePreviewImage('electricity_source')"
                  >
                </div>
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small uk-margin-top"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Foto Sumber Air<span class="uk-text-danger">*</span>
            </div>
            <div
              class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl js-upload"
              uk-form-custom
            >
              <div
                v-if="isEdit"
              >
                <input
                  v-validate="{ 'required':false }"
                  name="water_source"
                  class="{'uk-form-danger': errors.has('water_source')}"
                  accept="image/png,image/jpg,image/jpeg,application/pdf"
                  type="file"
                  multiple
                  :disabled="!isEdit"
                  @change="(event) => handleUploadFile(event, 'water_source')"
                >
                <div 
                  class="uk-inline"
                  tabindex="-1"
                >
                  <div
                    class="uk-input"
                  >
                    <span
                      style="max-width: 90%;"
                      class="uk-panel uk-text-truncate"
                    >
                      {{ formData.water_source ? formData.water_source.name : "Unggah Dokumen" }}
                    </span>
                    <img
                      v-lazy="formData.water_source ? `${images}/icon/close-green.svg` : `${images}/icon/logout.svg`"
                      alt="icon action"
                      class="uk-form-icon uk-form-icon-flip icon-upload"
                      uk-icon
                    >
                  </div>
                  <div
                    v-show="errors.has('water_source')"
                    class="uk-text-small uk-text-danger"
                  >
                    {{ errors.first('water_source') }}
                  </div>
                </div>
              </div>
              <div v-else>
                <div
                  class="uk-width-1-1 uk-flex uk-flex-between"
                  tabindex="2"
                  :class="`${!isEdit ? 'uk-input-disabled' : 'uk-input'}`"
                >
                  <span class="uk-panel uk-text-truncate">
                    {{ formData.water_source ? formData.water_source.name :'-' }}
                  </span>
                  <img
                    v-if="formData.water_source "
                    v-lazy="`${images}/icon/eye-line.svg`"
                    alt="icon action"
                    class="icon-upload"
                    @click="handlePreviewImage('water_source')"
                  >
                </div>
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small uk-margin-top"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Foto Gudang Pakan<span class="uk-text-danger">*</span>
            </div>
            <div
              class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl js-upload"
              uk-form-custom
            >
              <div
                v-if="isEdit"
              >
                <input
                  v-validate="{ 'required':false }"
                  name="feed_warehouse"
                  class="{'uk-form-danger': errors.has('feed_warehouse')}"
                  accept="image/png,image/jpg,image/jpeg,application/pdf"
                  type="file"
                  multiple
                  :disabled="!isEdit"
                  @change="(event) => handleUploadFile(event, 'feed_warehouse')"
                >
                <div 
                  class="uk-inline"
                  tabindex="-1"
                >
                  <div
                    class="uk-input"
                  >
                    <span
                      style="max-width: 90%;"
                      class="uk-panel uk-text-truncate"
                    >
                      {{ formData.feed_warehouse ? formData.feed_warehouse.name : "Unggah Dokumen" }}
                    </span>
                    <img
                      v-lazy="formData.feed_warehouse ? `${images}/icon/close-green.svg` : `${images}/icon/logout.svg`"
                      alt="icon action"
                      class="uk-form-icon uk-form-icon-flip icon-upload"
                      uk-icon
                    >
                  </div>
                  <div
                    v-show="errors.has('feed_warehouse')"
                    class="uk-text-small uk-text-danger"
                  >
                    {{ errors.first('feed_warehouse') }}
                  </div>
                </div>
              </div>
              <div v-else>
                <div
                  class="uk-width-1-1 uk-flex uk-flex-between"
                  tabindex="2"
                  :class="`${!isEdit ? 'uk-input-disabled' : 'uk-input'}`"
                >
                  <span class="uk-panel uk-text-truncate">
                    {{ formData.feed_warehouse ? formData.feed_warehouse.name :'-' }}
                  </span>
                  <img
                    v-if="formData.feed_warehouse "
                    v-lazy="`${images}/icon/eye-line.svg`"
                    alt="icon action"
                    class="icon-upload"
                    @click="handlePreviewImage('feed_warehouse')"
                  >
                </div>
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small uk-margin-top"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Foto Mess<span class="uk-text-danger">*</span>
            </div>
            <div
              class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl js-upload"
              uk-form-custom
            >
              <div
                v-if="isEdit"
              >
                <input
                  v-validate="{ 'required':false }"
                  name="homestead"
                  class="{'uk-form-danger': errors.has('homestead')}"
                  accept="image/png,image/jpg,image/jpeg,application/pdf"
                  type="file"
                  multiple
                  :disabled="!isEdit"
                  @change="(event) => handleUploadFile(event, 'homestead')"
                >
                <div 
                  class="uk-inline"
                  tabindex="-1"
                >
                  <div
                    class="uk-input"
                  >
                    <span
                      style="max-width: 90%;"
                      class="uk-panel uk-text-truncate"
                    >
                      {{ formData.homestead ? formData.homestead.name : "Unggah Dokumen" }}
                    </span>
                    <img
                      v-lazy="formData.homestead ? `${images}/icon/close-green.svg` : `${images}/icon/logout.svg`"
                      alt="icon action"
                      class="uk-form-icon uk-form-icon-flip icon-upload"
                      uk-icon
                    >
                  </div>
                  <div
                    v-show="errors.has('homestead')"
                    class="uk-text-small uk-text-danger"
                  >
                    {{ errors.first('homestead') }}
                  </div>
                </div>
              </div>
              <div v-else>
                <div
                  class="uk-width-1-1 uk-flex uk-flex-between"
                  tabindex="2"
                  :class="`${!isEdit ? 'uk-input-disabled' : 'uk-input'}`"
                >
                  <span class="uk-panel uk-text-truncate">
                    {{ formData.homestead ? formData.homestead.name :'-' }}
                  </span>
                  <img
                    v-if="formData.homestead "
                    v-lazy="`${images}/icon/eye-line.svg`"
                    alt="icon action"
                    class="icon-upload"
                    @click="handlePreviewImage('homestead')"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="!dataDetail.acquisition_facility || isEdit"
        class="uk-child-width-expand@s uk-grid-small uk-margin-top"
        uk-grid
      >
        <div class="uk-width-1-1 uk-text-right">
          <button
            class="uk-button uk-button-primary uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
            type="button"
            :disabled="isLoading || disabledEdit"
            @click="handleSubmit"
          >
            <span
              v-if="isLoading"
              uk-spinner
              class="button-spinner uk-text-muted"
            />
            <span v-else>
              <img
                v-lazy="`${images}/icon/${'save' }.svg`"
                alt="icon action"
                class="uk-margin-small-right"
              >
            </span>
            Simpan
          </button>
        </div>
      </div>
      <div
        v-else-if="!isEdit"
        class="uk-child-width-expand@s uk-grid-small uk-margin-top"
        uk-grid
      >
        <div class="uk-width-1-1 uk-text-right">
          <button
            class="uk-button uk-button-primary uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
            type="button"
            :disabled="disabledEdit"
            @click="editForm"
          >
            <img
              v-if="!disabledEdit"
              v-lazy="`${images}/icon/${'edit' }.svg`"
              alt="icon action"
              class="uk-margin-small-right"
            >
            Edit
          </button>
        </div>
      </div>
    </div>
    <modal-preview
      :target-preview="blobString"
      :target-field="'modal-preview-image-facility'"
    />
  </div>
</template>

<script>
import { PREFIX_IMAGE } from '@/utils/constant'
import { mapActions, mapGetters } from 'vuex'
import { stringNotifDanger, notificationSuccess } from '@/utils/notification'
import { compressedImage } from '@/utils/formater'
import ModalPreview from '@/components/globals/modal/ModalPreview'

export default {
  components:{
    ModalPreview
  },
  props: {
    // eslint-disable-next-line vue/require-prop-types
    dataDetailMaster: { required: true }
  },
  data() {
    return {
      isLoading:false,
      images: PREFIX_IMAGE,
      image_url: process.env.VUE_APP_IMAGE_URL,
      formData: {
        acquisition_id: this.$route.params.id
      },
      blobString: '',
      available: 1,
      notAvailable: 2,
      isEdit: false,
      disabledEdit: false
    }
  },
  computed : {
    ...mapGetters({
      dataDetail: 'acquisition/dataDetail',
      getDisabledEdit: 'acquisition/disabledEdit'
    })
  },
  watch:{
    dataDetail(){
      if(this.dataDetail){
        this.disabledEdit = this.getDisabledEdit
      }
    }
  },
  mounted(){
    if(this.dataDetailMaster.acquisition_facility){
      this.handleDetail()
    } else {
      this.isEdit= true
    }
  },
  methods: {
    handleDetail() {
      const detailFacility = this.dataDetailMaster.acquisition_facility
      for (var key in detailFacility) {
        if(typeof detailFacility[key] == 'number' || key == 'partner_assistant_name'){
          this.formData = {
            ...this.formData,
            [key]: detailFacility[key]
          }
        } else {
          if(detailFacility[key] != ''){
            this.formData = {
              ...this.formData,
              [key.toLowerCase()]:{
                name: detailFacility[key] ? detailFacility[key].split('/')[3] : '',
                data: detailFacility[key]
              }
            }
          }
        }
      }
    },
    async handleUploadFile(data, target) {
      const file = data.target.files[0]
      const fileType = file.type
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"]
      if (file) {
        if (allowedTypes.includes(fileType)) {
          const compressedFile = await compressedImage(file, 1)
          this.formData = {
            ...this.formData,
            [target]: {
              data: compressedFile,
              name: compressedFile.name
            }
          }
        } else {
          stringNotifDanger('Format File Salah! Pastikan File Berupa = ' + allowedTypes.join(', '))
        }
      }
    },
    ...mapActions({
      storeFacility: 'acquisition/storeFacility',
      updateFacility: 'acquisition/updateFacility',
      getDataDetail: 'acquisition/getDataDetail'
    }),
    editForm(){
      this.isEdit = true
    },
    handlePreviewImage(target) {
      const dataPreview = this.formData[target].data
      this.blobString = dataPreview.includes('base64') ? dataPreview : this.image_url + dataPreview
      return window.UIkit.modal('#modal-preview-image-facility').show()
    },
    async handleSubmit() {
      let dataRadio = ['handphone', 'generator', 'water', 'electricity', 'partner_assistant']
      if(this.dataDetailMaster.acquisition_facility==null){
        this.$validator.validateAll().then(async (success) => {
          if (success) {
            if (this.$validator.errors.any()) return
            this.isLoading = true
            if(this.formData.partner_assistant != this.available){
              this.formData.partner_assistant_name = ''
            }
            const formStore = new FormData
            for (const key of dataRadio) {
              if (!Object.prototype.hasOwnProperty.call(this.formData, key)) {
                this.formData[key] = null
                formStore.append(key, 0)
              } else{
                formStore.append(key, this.formData[key])
              }
            }
            for (var key in this.formData) {
              if(typeof this.formData[key] == 'object' && this.formData[key] != null){
                formStore.append(key, this.formData[key].data)
              }
            }
            if(this.formData.partner_assistant_name != ''){
              formStore.append('partner_assistant_name', this.formData.partner_assistant_name)
            }
            formStore.append('acquisition_id', this.formData.acquisition_id)
            await this.storeFacility(formStore)
            const res  = await this.$store.getters['acquisition/storeFaciltyResponse']
            if(res.toLowerCase() == 'ok'){
              notificationSuccess('Berhasil, Menyimpan Sarana Pendukung')
              setTimeout(() => {
                window.location.reload()
              }, 1000)
            } else {
              this.isLoading = false
            }
          }
        })
      } else {
        this.$validator.validateAll().then(async (success) => {
          if (success) {
            if (this.$validator.errors.any()) return
            this.isLoading = true
            if(this.formData.partner_assistant != this.available){
              this.formData.partner_assistant_name = ''
            }
            const formUpdate = new FormData
            formUpdate.append('id', this.dataDetailMaster.acquisition_facility.id)
            for (var key in this.formData) {
              if(typeof this.formData[key] == 'object' ){
                if(typeof this.formData[key].data != 'string'){
                  formUpdate.append(key, this.formData[key].data)
                }
              } else {
                formUpdate.append(key, this.formData[key])
              }
            }
            await this.updateFacility(formUpdate)
            const res  = await this.$store.getters['acquisition/storeFaciltyResponse']
            if(res.toLowerCase() == 'ok'){
              notificationSuccess('Berhasil, Ubah Sarana Pendukung')
              await this.getDataDetail(this.$route.params.id)
              setTimeout(() => {
                this.isEdit = false
                this.isLoading = false
              }, 1000)
            } else {
              this.isLoading = false
            }
          }
        })
      }
    }
  }
}
</script>
<style scoped>
 .icon-upload {
    padding: 8px;
  }

  .uk-form-icon {
    z-index: 1;
  }
</style>
