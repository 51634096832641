<template>
  <div
    class="uk-child-width-expand@s uk-grid-small"
    uk-grid
  >
    <div class="uk-width-1-1 uk-text-left">
      <strong>
        Pilih Tanggal Rencana
      </strong>
    </div>
    <div class="uk-width-1-1">
      <div
        class="uk-child-width-expand@s uk-grid-small"
        uk-grid
      >
        <div class="uk-width-1-1@s uk-width-1-2@m uk-width-1-2@l uk-width-1-2@xl uk-margin-auto-vertical uk-text-left">
          <span class="label-green">Rencana Tanda Tangan</span>
        </div>
        <div class="uk-width-1-1@s uk-width-1-2@m uk-width-1-2@l uk-width-1-2@xl">
          <div class="uk-inline">
            <datepicker
              ref="date_contract"
              v-model="formData.date_contract"
              v-validate="'required'"
              name="date_contract"
              input-class="uk-input"
              placeholder="dd/mm/yyyy"
              :disabled-dates="startDisabled"
            />
            <span>
              <img
                v-lazy="`${images}/icon/date.svg`"
                alt="icon arrow"
                class="uk-form-icon uk-form-icon-flip icon-upload"
              >
            </span>
          </div>
          <div
            v-show="errors.has('date_contract')"
            class="uk-text-small uk-text-danger"
          >
            {{ errors.first('date_contract') }}
          </div>
        </div>
      </div>
    </div>
    <div class="uk-width-1-1 uk-text-right uk-margin-top">
      <button
        class="uk-button uk-button-primary uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
        type="button"
        :disabled="isLoading"
        @click="handleSubmit()"
      >
        <span
          v-if="isLoading"
          uk-spinner
          class="button-spinner uk-text-muted"
        />
        <span v-else>
          <img
            v-lazy="`${images}/icon/${'save' }.svg`"
            alt="icon action"
            class="uk-margin-small-right"
          >
        </span>
        Simpan
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Datepicker from 'vuejs-datepicker'
import { dateString } from '@/utils/formater'

export default {
  components: {
    Datepicker
  },
  data() {
    return {
      isLoading: false,
      formData: {
        acquisition_id: this.$route.params.id
      },
      startDisabled: {
        to: ''
      }
    }
  },
  computed : {
    ...mapGetters({
      dataDetail: 'acquisition/dataDetail',
      dateContractUploaded: 'acquisition/dateContractUploaded'
    })
  },
  mounted() {
    if(this.dataDetail){
      this.startDisabled = {
        to: new Date(new Date(this.dataDetail.updated_at_verification).setHours(0, 0, 0, 0))
      }
    }
  },
  methods: {
    ...mapActions({
      uploadDateContract: 'acquisition/uploadDateContract',
      downloadSpkTemplate: 'acquisition/downloadSpkTemplate'
    }),
    async handleSubmit(){
      this.$validator.validateAll().then(async (success) => {
        if (success) {
          this.isLoading = true
          this.formData.date_contract = dateString(this.formData.date_contract)
          await this.uploadDateContract(this.formData)
          this.isLoading = false
          await this.downloadSpkTemplate()
          if(this.dateContractUploaded) return setTimeout(() => {
            window.location = `/admin/akuisisi/detail/${this.formData.acquisition_id}`
          }, 700)
        }
      })
    }
  }
}
</script>

