<template>
  <!-- This section wrapper is used for all content -->
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
    <div uk-grid>
      <div class="uk-width-2-3@s font-header">
        {{ dataHeader.title }}
      </div>
      <div
        v-if="dataHeader.isBreadcrumb"
        class="uk-width-1-3@s"
      >
        <bread-crumb :bread-crumb="dataHeader.breadcrumb" />
      </div>
    </div>

    <slot />
  </div>
</template>

<script>
import BreadCrumb from '@/components/globals/breadcrumb'

export default {
  components: { BreadCrumb },
  props: {
    dataHeader: {
      required: true,
      type: Object
    }
  }
}
</script>
