<template>
  <div class="uk-margin-bottom">
    <div class="uk-card uk-card-default uk-padding-small kandang-card">
      <ul>
        <!-- retire -->
        <li
          v-if=" statusRetire == payloadDetail.status_acquisition "
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        > 
          <div class="uk-width-1-3 uk-width-1-5@s uk-width-1-4@m uk-width-1-6@l uk-flex uk-flex-right uk-flex-top">
            <div class="uk-margin-small-right date-progress">
              <h3>
                {{ dateFormatStandartFilterUtc(payloadDetail.status_acquisition_retirement.created_at) }}
              </h3>
              <span>
                {{ formatHoursString(payloadDetail.status_acquisition_retirement.created_at) }}
              </span>
            </div>
            <div class="progress-bar-step-failed">
              <img
                v-lazy="`${images}/icon/incorrect.svg`"
                alt="icon check"
                class="icon-check"
              >
            </div>
          </div>
          <div class="uk-width-3-5 uk-width-3-5@s uk-width-3-4@m uk-width-3-4@l desc-progress">
            <h3 class="paranje-danger">
              Mengundurkan Diri
            </h3>
            <p class="paranje-danger-fw">
              Alasan : {{ payloadDetail.status_acquisition_retirement.reason }}
            </p>
          </div>
        </li>
        <!-- Section Paranje Farmers -->
        <li
          v-if="statusFarmers.includes(payloadDetail.status_acquisition) && payloadDetail.status_acquisition_verification.verified_date"
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        > 
          <div class="uk-width-1-3 uk-width-1-5@s uk-width-1-4@m uk-width-1-6@l uk-flex uk-flex-right uk-flex-top">
            <div class="uk-margin-small-right date-progress">
              <h3>
                {{ dateFormatStandartFilterUtc(payloadDetail.status_acquisition_verification.verified_date) }}
              </h3>
              <span>
                {{ formatHoursString(payloadDetail.status_acquisition_verification.verified_date) }}
              </span>
            </div>
            <div class="progress-bar-step">
              <img
                v-lazy="`${images}/icon/check-circle.svg`"
                alt="icon check"
                class="icon-check"
              >
            </div>
          </div>
          <div class="uk-width-3-5 uk-width-3-5@s uk-width-3-4@m uk-width-3-4@l desc-progress">
            <h3>PARANJE Farmers</h3>
            <div
              v-show="dataListInstallment.length"
            >
              <!-- <span>{{ dataListInstallment.length > 0 ? dateFormatStandartFilter(dataListInstallment[0].updated_at) : '-' }}</span> -->
              <p class="inform">
                Dana Jaminan
              </p>
              <div
                v-for="(item, index) in dataListInstallmentReverse"
                :key="index"
                class="uk-margin-bottom"
              >
                <div class="uk-width-1-4 uk-width-1-4@l uk-width-1-4@xl">
                  <div class="uk-flex uk-flex-between">
                    <span class="inform">Pembayaran Ke</span><span><b>{{ item.termin }}</b></span>
                  </div>
                  <div class="uk-flex uk-flex-between">
                    <span class="inform">Tanggal</span><span class="inform"><b>{{ dateFormatStandartFilter(item.date_payment) }}</b></span>
                  </div>
                  <div class="uk-flex uk-flex-between">
                    <span class="inform">Nominal</span>
                    <span class="inform">
                      <b>
                        {{ handleCurrency(item.amount) }}
                      </b>
                    </span>
                  </div>
                </div>
                <div
                  class="uk-child-width-expand@s uk-grid-small uk-margin-small-bottom"
                  uk-grid
                >
                  <template v-if="item.evidence_transfer_file">
                    <div 
                      v-if="item.evidence_transfer_file.includes('pdf')"
                      class="uk-width-1-1 uk-width-1-2@l uk-width-1-2@xl iframe-wrapper"
                    >
                      <iframe 
                        :src="`${image_url}${item.evidence_transfer_file}`" 
                      />
                      <button 
                        class="overlay-button" 
                        @click="handlePreviewImage(item.evidence_transfer_file)"
                      > 
                        <img
                          v-lazy="`${images}/icon/eye.svg`"
                          alt="Your Icon Description"
                        >
                      </button>
                    </div>
                    <div
                      v-else
                      class="list-doc-image uk-width-1-4 uk-width-1-4@l uk-width-1-4@xl img-click"
                      @click="handlePreviewImage(item.evidence_transfer_file)"
                    >
                      <img
                        v-lazy="`${image_url}${item.evidence_transfer_file}`"
                        alt="img termin"
                      >
                    </div>
                  </template>
                  <template v-if="item.receipt_file">
                    <div
                      v-if="item.receipt_file.includes('pdf')"
                      class="uk-width-1-1 uk-width-1-2@l uk-width-1-2@xl iframe-wrapper"
                    >
                      <div @click="handlePreviewImage(item.receipt_file)">
                        <iframe :src="`${image_url}${item.receipt_file}`" />
                        <button 
                          class="overlay-button" 
                          @click="handlePreviewImage(item.receipt_file)"
                        > 
                          <img
                            v-lazy="`${images}/icon/eye.svg`"
                            alt="Your Icon Description"
                          >
                        </button>
                      </div>
                    </div>
                    <div
                      v-else
                      class="list-doc-image uk-width-1-4 uk-width-1-4@l uk-width-1-4@xl img-click"
                      @click="handlePreviewImage(item.receipt_file)"
                    >
                      <img
                        v-lazy="`${image_url}${item.receipt_file}`"
                        alt="img termin"
                      >
                    </div>
                  </template>
                </div>
                <div
                  v-if="!item.receipt_file"
                  class="uk-width-1-1 uk-text-left uk-margin-small-bottom"
                >
                  <button
                    class="uk-button uk-button-default padding-responsive uk-width-expand uk-width-auto@s"
                    type="button"
                    :disabled="disabledEdit"
                    @click="() => handleUploadReceipt('upload-receipt', item)"
                  >
                    <strong>Unggah Kuitansi</strong>
                  </button>
                </div>
              </div>
            </div>
            <div
              v-if="statusFarmers.includes(payloadDetail.status_acquisition)"
              class="uk-width-1-1 uk-text-left uk-margin-small-top"
            >
              <div 
                v-if="!dataDetail.acquisition_facility && dataDetail.acquisition_info.document_number"
                class="uk-margin-bottom"
              >
                <p class="inform">
                  Cek Sarana Pendukung
                </p>
                <button
                  class="uk-button uk-button-default uk-text-bold padding-responsive uk-width-expand uk-width-auto@s"
                  type="button"
                  @click="handleVerifyDoc(3)"
                >
                  Cek Sarana Pendukung
                </button>
              </div>
              <div
                v-show="dataDetail.acquisition_farmer.document_spk_file.includes('/')"
              >
                <p class="inform">
                  <b>
                    {{ dateFormatStandartFilter(dataDetail.acquisition_farmer.date_realization_spk) }}
                  </b>
                </p>
                <p class="inform">
                  Tanggal Realisasi SPK
                </p>
              </div>
              <button
                v-if="!dataDetail.acquisition_farmer.date_contract "
                class="uk-button uk-button-primary padding-responsive uk-width-expand uk-width-auto@s uk-button-default"
                type="button"
                :disabled="disabledEdit"
                @click="() => handleUploadSpk('spk-plan')"
              >
                <strong>
                  {{ dataDetail.acquisition_farmer.document_spk_file.includes('/') ? 'Unggah' : 'Unduh' }} Dokumen SPK
                </strong>
              </button>
              <button
                v-if="dataDetail.acquisition_farmer.date_contract"
                class="uk-button uk-button-primary padding-responsive uk-width-expand uk-width-auto@s uk-margin-small-bottom"
                :class="dataDetail.acquisition_farmer.document_spk_file.includes('/') ? 'uk-button-primary' : 'uk-button-default'"
                type="button"
                :disabled="!dataDetail.acquisition_farmer.document_spk_file.includes('/') ? disabledEdit : false"
                @click="() => handleUploadSpk(!dataDetail.acquisition_farmer.document_spk_file.includes('/') ? 'upload-spk' : 'download')"
              >
                <strong>
                  {{ !dataDetail.acquisition_farmer.document_spk_file.includes('/') ? 'Unggah' : 'Unduh' }} Dokumen SPK
                </strong>
              </button>
            </div>
            <div
              v-show="dataDetail.acquisition_farmer.date_contract"
            >
              <p class="inform">
                <b>
                  {{ dateFormatStandartFilter(dataDetail.acquisition_farmer.date_contract) }}
                </b>
              </p>
              <p class="inform">
                Rencana Tanda Tangan Kontrak
              </p>
            </div>
          </div>
        </li>
        <!-- Section Submit & Verifikasi -->
        <li
          v-if="statusSubmitVerify.includes(payloadDetail.status_acquisition)"
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        > 
          <div class="uk-width-1-3 uk-width-1-5@s uk-width-1-4@m uk-width-1-6@l uk-flex uk-flex-right uk-flex-top">
            <div class="uk-margin-small-right date-progress">
              <h3>{{ dateFormatStandartFilter(payloadDetail.status_acquisition_potential.updated_at_potential) }}</h3>
              <span>{{ formatHoursString(payloadDetail.status_acquisition_potential.updated_at_potential) }}</span>
            </div>
            <div class="progress-bar-step">
              <img
                v-lazy="`${images}/icon/check-circle.svg`"
                alt="icon check"
                class="icon-check"
              >
            </div>
          </div>
          <div class="uk-width-3-5 uk-width-3-5@s uk-width-3-4@m uk-width-3-4@l desc-progress uk-margin-bottom">
            <h3>Submit & Verifikasi</h3>
            <!-- Section if status aquisition is 6 or has been verified -->
            <template v-if="statusFarmers.includes(payloadDetail.status_acquisition)">
              <p class="inform">
                <b>
                  {{ dateFormatStandartFilter(payloadDetail.status_acquisition_verification.verified_date) }}
                </b>
                <br>
                Verifikasi Berhasil
                <br>
                <b>
                  {{ dateFormatStandartFilter(payloadDetail.status_acquisition_verification.administrative_submission_date) }}
                </b>
                <br>
                Pengajuan Administrasi
              </p>
            </template>
            <p
              v-if="payloadDetail.status_acquisition == encodeStatusMitra(4)"
              class="inform"
            >
              Pengajuan Administrasi
            </p>
            <template v-if="payloadDataDetail.acquisition_verification.id && payloadDataDetail.status_acquisition == encodeStatusMitra(5)">
              <p class="inform">
                Proses Verifikasi
              </p>
              <div class="uk-width-1-1 uk-text-left uk-margin-small-top">
                <button
                  class="uk-button uk-button-default padding-responsive uk-width-expand uk-width-auto@s"
                  type="button"
                  :disabled="disabledEdit"
                  @click="handleVerifyDoc(2)"
                >
                  Verifikasi Dokumen
                  <img
                    v-lazy="`${images}/icon/document.svg`"
                    alt=""
                    uk-icon
                  >
                </button>
              </div>
            </template>
            <template
              v-if="!payloadDataDetail.acquisition_verification.id || payloadDetai == encodeStatusMitra(5)"
            >
              <div class="uk-width-1-1 uk-text-left uk-margin-small-top">
                <button
                  v-show="!payloadDataDetail.acquisition_verification.acquisition_id"
                  class="uk-button uk-button-default padding-responsive uk-width-expand uk-width-auto@s"
                  type="button"
                  :disabled="disabledEdit"
                  @click="() => handleActionWorty('upload_doc')"
                >
                  Unggah Dokumen
                  <img
                    v-lazy="`${images}/icon/document.svg`"
                    alt=""
                    class="margin-left-extra-small"
                  >
                </button>
              </div>
            </template>
          </div>
        </li>
        <!-- Section Not Worthy -->
        <li
          v-if="payloadDetail.status_acquisition==encodeStatusMitra(3)"
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        > 
          <div class="uk-width-1-3 uk-width-1-5@s uk-width-1-4@m uk-width-1-6@l uk-flex uk-flex-right uk-flex-top">
            <div class="uk-margin-small-right date-progress">
              <h3>
                {{ dateFormatStandartFilterUtc(payloadDataDetail.updated_at_potential) }}
              </h3>
              <span>
                {{ formatHoursString(payloadDataDetail.updated_at_potential) }}
              </span>
            </div>
            <div class="progress-bar-step-failed">
              <img
                v-lazy="`${images}/icon/incorrect.svg`"
                alt="icon check"
                class="icon-check"
              >
            </div>
          </div>
          <div class="uk-width-3-5 uk-width-3-5@s uk-width-3-4@m uk-width-3-4@l desc-progress uk-margin-bottom">
            <h3 class="paranje-danger">
              Mitra Potensial - Tidak Layak
            </h3>
            <p class="paranje-danger-fw">
              Alasan : 
              <span class="paranje-danger-fw">
                <span
                  :class="{ 'uk-panel uk-text-truncate' : !hideTruncate}"
                  style="font-weight: bold;"
                >
                  <a
                    class="paranje-danger-fw"
                    @click="truncateOption()"
                  >{{ payloadDetail.status_acquisition_potential.reason == 'Lainnya' ? payloadDetail.status_acquisition_potential.other_reason : payloadDetail.status_acquisition_potential.reason }}</a>
                </span>
              </span> 
            </p>
            <p class="paranje-danger-fw">
              Tanggal Survei:
              <span
                class="paranje-danger-fw"
                style="font-weight: bold;"
              >
                {{ dateFormatStandartFilter(payloadDetail.status_acquisition_potential.survey_start_date) + ' - ' + dateFormatStandartFilter(payloadDetail.status_acquisition_potential.survey_end_date) }}
              </span>
            </p>
            <div 
              class="imgs-container"
              uk-grid
            >
              <div class="img-and-text-container">
                <img
                  v-lazy="payloadDataDetail.acquisition_location_photo.east_land_photo ? `${image_url}${payloadDataDetail.acquisition_location_photo.east_land_photo}` : `${images}/img-not-found.png`"
                  class="preview-image"
                  alt="img-thumbnail"
                  uk-img
                >
                <a @click="handlePreviewImage(payloadDataDetail.acquisition_location_photo.east_land_photo)">
                  <span class="icon">
                    <img
                      v-lazy="`${images}/icon/eye-line.svg`"
                      alt="Your Icon Description"
                    >
                  </span>
                </a>
                <span class="uk-margin-remove caps uk-text-center">Lahan Timur</span>
              </div>
              <div class="img-and-text-container">
                <img
                  v-lazy="payloadDataDetail.acquisition_location_photo.west_land_photo ? `${image_url}${payloadDataDetail.acquisition_location_photo.west_land_photo}` : `${images}/img-not-found.png`"
                  class="preview-image"
                  alt="img-thumbnail"
                  uk-img
                >
                <a @click="handlePreviewImage(payloadDataDetail.acquisition_location_photo.west_land_photo)">
                  <span class="icon">
                    <img
                      v-lazy="`${images}/icon/eye-line.svg`"
                      alt="Your Icon Description"
                    >
                  </span>
                </a>
                <span class="uk-margin-remove caps uk-text-center">Lahan Barat</span>
              </div>
              <div class="img-and-text-container">
                <img
                  v-lazy="payloadDataDetail.acquisition_location_photo.north_land_photo ? `${image_url}${payloadDataDetail.acquisition_location_photo.north_land_photo}` : `${images}/img-not-found.png`"
                  class="preview-image"
                  alt="img-thumbnail"
                  uk-img
                >
                <a @click="handlePreviewImage(payloadDataDetail.acquisition_location_photo.north_land_photo)">
                  <span class="icon">
                    <img
                      v-lazy="`${images}/icon/eye-line.svg`"
                      alt="Your Icon Description"
                    >
                  </span>
                </a>
                <span class="uk-margin-remove caps uk-text-center">Lahan Utara</span>
              </div>
              <div class="img-and-text-container">
                <img
                  v-lazy="payloadDataDetail.acquisition_location_photo.south_land_photo ? `${image_url}${payloadDataDetail.acquisition_location_photo.south_land_photo}` : `${images}/img-not-found.png`"
                  class="preview-image"
                  alt="img-thumbnail"
                  uk-img
                >
                <a @click="handlePreviewImage(payloadDataDetail.acquisition_location_photo.south_land_photo)">
                  <span class="icon">
                    <img
                      v-lazy="`${images}/icon/eye-line.svg`"
                      alt="Your Icon Description"
                    >
                  </span>
                </a>
                <span class="uk-margin-remove caps uk-text-center">Lahan Selatan</span>
              </div>
            </div>
          </div>
        </li>
        <!-- End Section Not Worthy -->
        <!-- Section Mitra Potensial -->
        <li
          v-if="statusMitraPotensial.includes(payloadDataDetail.status_acquisition)"
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        > 
          <div class="uk-width-1-3 uk-width-1-5@s uk-width-1-4@m uk-width-1-6@l uk-flex uk-flex-right uk-flex-top">
            <div class="uk-margin-small-right date-progress">
              <h3>
                {{ dateFormatStandartFilterUtc(payloadDetail.status_acquisition_prospect.updated_at_prospect) }}
              </h3>
              <span>
                {{ formatHoursString(payloadDetail.status_acquisition_prospect.updated_at_prospect) }}
              </span>
            </div>
            <div class="progress-bar-step">
              <img
                v-lazy="`${images}/icon/check-circle.svg`"
                alt="icon check"
                class="icon-check"
              >
            </div>
          </div>
          <div class="uk-width-3-5 uk-width-3-5@s uk-width-3-4@m uk-width-3-4@l desc-progress uk-margin-bottom">
            <h3>Mitra Potensial</h3>
            <div
              v-show="statusSubmitVerify.includes(payloadDataDetail.status_acquisition)" 
            >
              <p
                class="uk-margin-remove-top inform"
              >
                <b>
                  {{ dateFormatStandartFilter(payloadDetail.status_acquisition_potential.survey_start_date) + ' - ' + dateFormatStandartFilter(payloadDetail.status_acquisition_potential.survey_end_date) }}
                </b>
              </p>
            </div>
            <div
              v-if="isSurvey && payloadDataDetail.status_acquisition == encodeStatusMitra(2)"
            >
              <p
                class="inform"
              >
                Menunggu Verifikasi
              </p>
            </div>
            <p class="inform">
              Survei Lokasi Mitra Dan Uji Kelayakan
            </p>
            <p
              v-show="!payloadDataDetail.acquisition_info.id || payloadDataDetail.status_acquisition == encodeStatusMitra(3)" 
              class="inform"
            >
              Tanggal Rencana Survei : <b class="uk-text-bolder">{{ dateFormatStandartFilter(payloadDetail.status_acquisition_prospect.site_survey_plan_date) }}</b>
            </p>
            <p
              v-show="isSurvey && payloadDetail.status_acquisition_potential.survey_end_date && payloadDataDetail.status_acquisition != encodeStatusMitra(3) && payloadDataDetail.status_acquisition == encodeStatusMitra(2)" 
              class="inform"
            >
              Tanggal Survei : 
              <b>
                {{ dateFormatStandartFilter(payloadDetail.status_acquisition_potential.survey_start_date) + ' - ' + dateFormatStandartFilter(payloadDetail.status_acquisition_potential.survey_end_date) }}
              </b>
            </p>
            <div
              v-if="payloadDetail.status_acquisition == encodeStatusMitra(2) && !payloadDataDetail.acquisition_info.id"
              class="uk-width-1-1 uk-text-left uk-margin-small-top"
            >
              <button
                class="uk-button uk-button-default uk-width-expand uk-width-auto@s"
                type="button"
                @click="() => handleActionWorty('not_worty')"
              >
                Tidak Layak
              </button>
              <button
                class="uk-button uk-button-primary uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
                type="button"
                @click="() => handleActionWorty('worty')"
              >
                Layak
              </button>
            </div>
          </div>
        </li>
        <!-- Section Mitra Prospek -->
        <!-- If Not Interested -->
        <li
          v-if="payloadDetail.status_acquisition == encodeStatusMitra(1)"
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        > 
          <div class="uk-width-1-3 uk-width-1-5@s uk-width-1-4@m uk-width-1-6@l uk-flex uk-flex-right uk-flex-top">
            <div class="uk-margin-small-right date-progress">
              <h3>
                {{ dateFormatStandartFilterUtc(payloadDetail.status_acquisition_prospect.updated_at_prospect
                ) }}
              </h3>
              <span>
                {{ formatHoursString(payloadDetail.status_acquisition_prospect.updated_at_prospect
                ) }}
              </span>
            </div>
            <div class="progress-bar-step-failed">
              <img
                v-lazy="`${images}/icon/incorrect.svg`"
                alt="icon check"
                class="icon-check"
              >
            </div>
          </div>
          <div class="uk-width-3-5 uk-width-3-5@s uk-width-3-4@m uk-width-3-4@l desc-progress uk-margin-bottom">
            <h3 class="paranje-danger">
              Mitra Prospek - Tidak Tertarik
            </h3>
            <p class="paranje-danger-fw">
              Alasan : 
              <span class="paranje-danger-fw">
                <span
                  :class="{ 'uk-panel uk-text-truncate' : !hideTruncate}"
                  style="font-weight: bold;"
                >
                  <a
                    class="paranje-danger-fw"
                    @click="truncateOption()"
                  >
                    {{ payloadDetail.status_acquisition_prospect.reason == 'Lainnya' ? payloadDetail.status_acquisition_prospect.other_reason : payloadDetail.status_acquisition_prospect.reason }}
                  </a>
                </span>
              </span> 
            </p>
            <p class="paranje-danger-fw">
              Tanggal Presentasi:
              <span
                class="paranje-danger-fw"
                style="font-weight: bold;"
              >
                {{ dateFormatStandartFilter(payloadDetail.status_acquisition_prospect.present_start_date) + ' - ' + dateFormatStandartFilter(payloadDetail.status_acquisition_prospect.present_end_date) }}
              </span>
            </p>
          </div>
        </li>
        <!-- End of If Not Interested -->
        <li
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-3 uk-width-1-5@s uk-width-1-4@m uk-width-1-6@l uk-flex uk-flex-right uk-flex-top">
            <div class="uk-margin-small-right date-progress">
              <h3>{{ dateFormatStandartFilter(payloadDetail.updated_at) }}</h3>
              <span>{{ formatHoursString(payloadDetail.updated_at) }}</span>
            </div>
            <div class="progress-bar-non-step">
              <img
                v-lazy="`${images}/icon/check-circle.svg`"
                alt="icon check"
                class="icon-check"
              >
            </div>
          </div>
          <div class="uk-width-3-5 uk-width-3-5@s uk-width-3-4@m uk-width-3-4@l desc-progress">
            <h3>Mitra Prospek</h3>
            <p
              v-if="payloadDetail.status_acquisition_prospect.present_end_date"
              class="uk-margin-remove-top inform"
            >
              <b>
                {{ dateFormatStandartFilter(payloadDetail.status_acquisition_prospect.present_start_date) + ' - ' + dateFormatStandartFilter(payloadDetail.status_acquisition_prospect.present_end_date) }}
              </b>
            </p>
            <p class="inform">
              Presentasi Paranje Program
            </p>
            <div
              v-show="!payloadDetail.status_acquisition_prospect.present_end_date"
              class="uk-width-1-1 uk-text-left uk-margin-small-top"
            >
              <button
                class="uk-button uk-button-default uk-width-expand uk-width-auto@s"
                type="button"
                @click="() => handleActionInterested('not_interest')"
              >
                Tidak Tertarik
              </button>
              <button
                class="uk-button uk-button-primary uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
                type="button"
                @click="() => handleActionInterested('interest')"
              >
                Tertarik
              </button>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <MainModal>
      <!-- Response Pop-up Mitra Prospect - Interested -->
      <template v-if="statusProspect == 'interest'">
        <partner-prospect :status-prospect="statusProspect" />
      </template>

      <!-- Response Pop-up Mitra Prospek - Not Interested -->
      <template v-if="statusProspect == 'not_interest'">
        <partner-prospect :status-prospect="statusProspect" />
      </template>

      <!-- Response Pop-up Mitra Potential - Not Worty -->
      <template v-if="statusProspect == 'not_worty'">
        <partner-potential :status-prospect="statusProspect" />
      </template>

      <!-- Response Pop-up Submit & Verification -->
      <template v-if="statusProspect == 'submit-verify'">
        <submit-and-verify />
      </template>

      <!-- Response Pop-up Submit & Verification -->
      <template v-if="statusProspect == 'spk-plan'">
        <spk-plan />
      </template>

      <template v-if="statusProspect == 'upload-spk'">
        <upload-spk />
      </template>

      <!-- Response Pop-up Submit & Verification -->
      <template v-if="statusProspect == 'upload-receipt'">
        <upload-receipt :data-receipt="dataReceipt" />
      </template>
    </MainModal>

    <modal-preview
      :target-preview="dataPreview"
      :target-field="'modal-preview-termin'"
      :wide="wideOption"
    />
    <modal-preview-image 
      :blob-pdf="blob" 
      :target-field="'modal-preview-pdf'"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { PREFIX_IMAGE } from '@/utils/constant'
import { dateString, dateUtcParanjeString, dateParanjeString, hourStringUtc, currency, encodeStatusMitra, snakeToCapitalized } from '@/utils/formater'
import MainModal from '@/components/globals/modal/MainModal'
import ModalPreview from '@/components/globals/modal/ModalPreview'
import ModalPreviewImage from '@/components/globals/modal/ModalPreviewPdfString'
import PartnerProspect from './status-mitra/PartnerProspect'
import PartnerPotential from './status-mitra/PartnerPotential'
import SubmitAndVerify from './status-mitra/SubmitAndVerify'
import UploadSpk from './status-mitra/UploadSpk'
import SpkPlan from './status-mitra/SpkPlan'
import UploadReceipt from './status-mitra/UploadReceipt'
import { notificationInfo } from '@/utils/notification'
import { isCanAccess } from '@/utils/auth'


export default {
  components: {
    MainModal,
    PartnerProspect,
    PartnerPotential,
    SubmitAndVerify,
    UploadSpk,
    SpkPlan,
    UploadReceipt,
    ModalPreview,
    ModalPreviewImage
  },
  data() {
    return {
      images: PREFIX_IMAGE,
      image_url: process.env.VUE_APP_IMAGE_URL,
      payloadDetail: {},
      payloadDataDetail: {},
      statusProspect: '',
      statusMitraPotensial: [],
      statusSubmitVerify: [],
      statusFarmers: [],
      statusFarmersNotUpload: [],
      dataListInstallment: [],
      dataListInstallmentReverse: [],
      dataReceipt: {},
      dataPreview: '',
      wideOption: '',
      hideTruncate: false,
      isSurvey: false,
      showThirdTab: false,
      blob: {},
      statusRetire: encodeStatusMitra(12),
      default_date: "0001-01-01T00:00:00Z",
      disabledEdit: false
    }
  },
  computed : {
    ...mapGetters({
      getDisabledEdit: 'acquisition/disabledEdit',
      dataDetail: 'acquisition/dataDetail',
      dataDetailStatus: 'acquisition/dataDetailStatus',
      listInstallment: 'acquisition/dataListInstallment',
      dataMetaInstallment: 'acquisition/dataMetaInstallment'
    })
  },
  watch: {
    async dataDetailStatus() {
      if (await this.dataDetailStatus) {
        this.payloadDetail = this.dataDetailStatus
      }
    },
    async dataDetail(){
      if (await this.dataDetail) {
        this.payloadDataDetail = this.dataDetail
        this.isSurvey = this.dataDetail.acquisition_info.id
        if(this.dataDetail.acquisition_verification.id || this.payloadDetail.status_acquisition_potential.updated_at_potential != this.default_date){
          this.statusSubmitVerify.push(this.encodeStatusMitra(12))
        }
        if(this.dataDetail.acquisition_farmer.id || this.payloadDetail.status_acquisition_verification.updated_at_verification != this.default_date){
          this.statusFarmers.push(this.encodeStatusMitra(12))
        }
        if(await this.getDisabledEdit){
          this.disabledEdit = this.getDisabledEdit
        }
      }
    }
  },
  mounted() {
    this.statusMitraPotensial.push(this.encodeStatusMitra(2))
    this.statusMitraPotensial.push(this.encodeStatusMitra(3))
    this.statusMitraPotensial.push(this.encodeStatusMitra(4))
    this.statusMitraPotensial.push(this.encodeStatusMitra(5))
    this.statusMitraPotensial.push(this.encodeStatusMitra(6))
    this.statusMitraPotensial.push(this.encodeStatusMitra(7))
    this.statusMitraPotensial.push(this.encodeStatusMitra(8))
    this.statusMitraPotensial.push(this.encodeStatusMitra(9))
    this.statusMitraPotensial.push(this.encodeStatusMitra(10))
    this.statusMitraPotensial.push(this.encodeStatusMitra(11))
    this.statusMitraPotensial.push(this.encodeStatusMitra(12))

    this.statusSubmitVerify.push(this.encodeStatusMitra(4))
    this.statusSubmitVerify.push(this.encodeStatusMitra(5))
    this.statusSubmitVerify.push(this.encodeStatusMitra(6))
    this.statusSubmitVerify.push(this.encodeStatusMitra(7))
    this.statusSubmitVerify.push(this.encodeStatusMitra(8))
    this.statusSubmitVerify.push(this.encodeStatusMitra(9))
    this.statusSubmitVerify.push(this.encodeStatusMitra(10))
    this.statusSubmitVerify.push(this.encodeStatusMitra(11))
    

    this.statusFarmersNotUpload.push(this.encodeStatusMitra(6))
    
    this.statusFarmers.push(this.encodeStatusMitra(6))
    this.statusFarmers.push(this.encodeStatusMitra(7))
    this.statusFarmers.push(this.encodeStatusMitra(8))
    this.statusFarmers.push(this.encodeStatusMitra(9))
    this.statusFarmers.push(this.encodeStatusMitra(10))
    this.statusFarmers.push(this.encodeStatusMitra(11))
    
    this.setDataMetaInstallment({
      ...this.dataMetaInstallment,
      acquisition_id: this.$route.params.id
    })
    this.handleListInstallment()
  },
  methods: {
    isCanAccess(action, menu){
      return isCanAccess(action, menu)
    },
    truncateOption(){
      if(this.hideTruncate == true){
        this.hideTruncate = false
      } else {
        this.hideTruncate = true
      }
    },
    encodeStatusMitra(val){
      return encodeStatusMitra(val)
    },
    ...mapActions({
      fetchingListInstallment: 'acquisition/fetchingListInstallment'
    }),
    ...mapMutations({
      setDataMetaInstallment: 'acquisition/SET_DATA_META_INSTALLMENT'
    }),
    handleCurrency(value) {
      return currency(value)
    },
    async handleListInstallment() {
      await this.fetchingListInstallment(this.dataMetaInstallment)
      this.dataListInstallment = this.listInstallment ? this.listInstallment : []
      this.dataListInstallmentReverse = this.listInstallment ? this.listInstallment.reverse() : []
    },
    dateFormatFilter(date) {
      return dateString(date)
    },
    dateFormatStandartFilterUtc(date) {
      return dateUtcParanjeString(date)
    },
    dateFormatStandartFilter(date) {
      return dateParanjeString(date)
    },
    formatHoursString(date) {
      return hourStringUtc(date)
    },
    handleActionInterested(field) {
      this.statusProspect = field
      window.UIkit.modal('#modal-standart').show()
    },
    handleActionWorty(field) {
      this.statusProspect = field
      if (field == 'worty') {
        this.$router.push(`/admin/akuisisi/detail/${this.$route.params.id}/mitra-potensial`)
      } else if (field == 'upload_doc') {
        this.$router.push(`/admin/akuisisi/detail/${this.$route.params.id}/submit-and-verification`)
      } else {
        window.UIkit.modal('#modal-standart').show()
      }
    },
    // handleVerifyDoc() {
    //   window.UIkit.modal('#modal-standart').show()
    //   this.statusProspect = 'submit-verify'
    // },
    handleVerifyDoc(number) {
      const parent = this.$parent
      // Access the ref to the progress partner tabs
      const progressPartnerTabs = parent.$refs.progressPartnerTabs
      // Switch to the Dokumen Pendukung tab (index number)
      window.UIkit.switcher(progressPartnerTabs).show(number)
    },
    handleUploadSpk(field) {
      if (field === 'upload-spk' || field === 'spk-plan') {
        this.statusProspect = field
        window.UIkit.modal('#modal-standart').show()
      } else {
        if (this.dataDetail) {
          if (this.dataDetail.acquisition_farmer.document_spk_file) {
            const dataPdf = this.image_url + this.dataDetail.acquisition_farmer.document_spk_file
            const link = document.createElement('a')
            link.href = dataPdf
            link.setAttribute('download', 'Dokumen-SPK')
            link.setAttribute('target', 'blank')
            link.click()
          } else {
            notificationInfo('Data tidak tersedia')
          }
        }
      }
    },
    handleUploadReceipt(field, data) {
      this.statusProspect = field
      this.dataReceipt = data
      window.UIkit.modal('#modal-standart').show()
    },
    handlePreviewImage(target) {
      if(this.payloadDataDetail.status_acquisition == encodeStatusMitra(3)){
        this.wideOptions = true
      } 
      if(target.includes('.pdf')){
        this.blob = {
          name: snakeToCapitalized(target), 
          file: target ? this.image_url + target : target
        }
        window.UIkit.modal('#modal-preview-pdf').show()
      } else {
        this.dataPreview = this.image_url + target
        window.UIkit.modal('#modal-preview-termin').show()
      }
    }
  }
}

</script>

<style scoped>
  .iframe-wrapper {
    position: relative;
    display: inline-block;
  }

  .overlay-button {
    position: absolute;
    opacity: 0.8;
    top: 12px;
    right: 12px;
    z-index: 2;
    background: none !important;
    border: none;
  }
  .overlay-button:hover {
    background: none !important;
    border: none;
    opacity: 1;
  }

  .inform {
    font-weight: 450;
    font-size: 16px;
    color: #1F2E28 !important;
  }
  .caps {
    margin-top: 8px !important;
    display: block;
    text-align: center;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    color: #000000 !important;
  }
  .icon img {
  max-width: 100%;
  max-height: 100%;
  }
  .img-and-text-container {
    position: relative;
    display: inline-block;
    width: 160.75px;
    padding: 0;
    margin-right: 26px;
    height: 129px;
  }
  .img-and-text-container .icon:hover {
    opacity: 1;
  }
  
  .img-and-text-container .icon {
  position: absolute;
  top: 0;
  width: 160.75px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  }
  .preview-image {
    width: 160.75px;
    height: 100px;
    border: 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 15px 3px rgb(41 45 90 / 10%);
  }

  .imgs-container {
    margin-left: 10px;
  }

  .kandang-card {
    border-radius: 0 0 16px 16px;
    box-shadow: 0px 19px 15px 3px rgb(41 45 90 / 10%);
  }

  .padding-responsive {
    padding: 0 1px;
  }

  .margin-left-extra-small {
    margin-left: 5px;
  }

  .icon-check {
    position: relative;
    z-index: 2;
    max-width: 24px;
  }

  .progress-bar-non-step {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    height: 100%;
    width: 100%;
  }

  .progress-bar-step {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    height: 100%;
    width: 100%;
  }

  .progress-bar-step::before {
    content: "";
    position: absolute;
    background: #004428;
    width: 5px;
    height: 100%;
    top: 20px;
    right: 57%;
    z-index: 1;
  }

  .progress-bar-step-failed {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    height: 100%;
  }
  .progress-bar-step-failed::before {
    content: "";
    position: absolute;
    background: #ED5E5E;
    width: 5px;
    height: 100%;
    top: 20px;
    right: 40%;
    z-index: 1;
  }

  .paranje-danger{
    color: #ED5E5E !important;
  }

  .paranje-danger-fw{
    color: #ED5E5E !important;
    font-size: 16px !important;
    text-decoration: none !important;
  }

  .date-progress {
    text-align: right;
  }

  .date-progress h3 {
    color: #202020;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 4px;
  }

  .date-progress span {
    color: #828282;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
  }

  .desc-progress {
    text-align: left;
  }

  .desc-progress h3 {
    color: #202020;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 4px;
  }

  .desc-progress p {
    color: #85AD9C;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 4px;
    margin-top: 0;
  }

  .desc-progress span {
    color: #3D5C4F;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 4px;
  }

  .price-termin {
    color: #3D5C4F;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    margin: 5px 0px;
  }

  .list-doc-image > img {
    border-radius: 8px;
    height: 115px;
    min-width: 100%;
    object-fit: cover;
  }

  object, iframe {
    width: 100%;
    height: 30vh;
    overflow: scroll;
  }

  @media screen and (min-width: 768px) {
    .padding-responsive {
      padding: 0 30px;
    }

    .progress-bar-step {
      width: auto;
    }

    .progress-bar-non-step {
      width: auto;
    }

    .progress-bar-step::before {
      height: 100%;
      top: 20px;
      right: 40%;
    }
  }

  @media screen and (min-width: 1200px) {
    .progress-tab {
      width: 100%;
      overflow-x: hidden;
      flex-wrap: wrap;
    }
  }

</style>

