<template>
  <div
    class="uk-child-width-expand@s uk-grid-small"
    uk-grid
  >
    <div class="uk-width-1-1 uk-text-left">
      <strong>
        Unggah Dokumen SPK
      </strong>
    </div>
    <div class="uk-width-1-1">
      <div
        class="uk-child-width-expand@s uk-grid-small"
        uk-grid
      >
        <div class="label-green uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical uk-text-left">
          Nomor SPK<span class="uk-text-danger">*</span>
        </div>
        <div
          class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl js-upload"
          uk-form-custom
        >
          <input
            v-model="formData.document_number"
            v-validate="{ 'required':true }"
            name="document_number"
            class="uk-input {'uk-form-danger': errors.has('document_number')}"
            type="text"
            placeholder="Masukkan Kode Proyek"
          >
          <div
            v-show="errors.has('document_number')"
            class="uk-text-small uk-text-danger"
          >
            {{ errors.first('document_number') }}
          </div>
        </div>
      </div>
      <div
        class="uk-child-width-expand@s uk-grid-small"
        uk-grid
      >
        <div class="label-green uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical uk-text-left">
          Dokumen SPK<span class="uk-text-danger">*</span>
        </div>
        <div
          class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl js-upload"
          uk-form-custom
        >
          <input
            v-validate="{ 'required':true }"
            name="document_spk_file"
            class="{'uk-form-danger': errors.has('document_spk_file')}"
            accept="application/pdf"
            type="file"
            multiple
            @change="(event) => handleUploadFile(event, 'document_spk_file')"
          >
          <div class="uk-inline">
            <img
              v-lazy="`${images}/icon/logout.svg`"
              alt="icon upload"
              class="uk-form-icon uk-form-icon-flip icon-upload"
            >
            <div class="uk-input uk-text-left">
              <span class="uk-panel uk-text-truncate">
                {{ formData.document_spk_file ? formData.document_spk_file.name : 'Unggah Dokumen' }}
              </span>
            </div>
          </div>
          <div
            v-show="errors.has('document_spk_file')"
            class="uk-text-small uk-text-danger"
          >
            {{ errors.first('document_spk_file') }}
          </div>
        </div>
      </div>
      <div
        class="uk-child-width-expand@s uk-grid-small"
        uk-grid
      >
        <div class="label-green uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical uk-text-left">
          Tanggal Realisasi SPK<span class="uk-text-danger">*</span>
        </div>
        <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
          <div class="uk-inline">
            <span
              class="uk-form-icon uk-form-icon-flip"
              uk-icon
            >
              <img
                v-lazy="`${images}/icon/date.svg`"
                alt="icon upload"
                class="uk-form-icon uk-form-icon-flip icon-upload"
              >
            </span>
            <datepicker
              ref="date_realization_spk"
              v-model="formData.date_realization_spk"
              v-validate="'required'"
              name="date_realization_spk"
              input-class="uk-input"
              placeholder="dd/mm/yyyy"
              :disabled-dates="realizationDisabled"
              :class="{'uk-form-danger': errors.has('date_realization_spk')}"
            />
          </div>
          <div
            v-show="errors.has('date_realization_spk')"
            class="uk-text-small uk-text-danger"
          >
            {{ errors.first('date_realization_spk') }}
          </div>
        </div>
      </div>
    </div>
    <div class="uk-width-1-1 uk-text-right uk-margin-top">
      <button
        class="uk-button uk-button-primary uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
        type="button"
        :disabled="isLoading"
        @click="handleSubmit"
      >
        <span
          v-if="isLoading"
          uk-spinner
          class="button-spinner uk-text-muted"
        />
        <span v-else>
          <img
            v-lazy="`${images}/icon/${'save' }.svg`"
            alt="icon action"
            class="uk-margin-small-right"
          >
        </span>
        Simpan
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { dateString } from '@/utils/formater'
import Datepicker from 'vuejs-datepicker'
import { PREFIX_IMAGE } from '@/utils/constant'

export default {
  components: {
    Datepicker
  },
  data() {
    return {
      isLoading: false,
      formData: {
        acquisition_id: this.$route.params.id
      },
      images: PREFIX_IMAGE
    }
  },
  computed : {
    ...mapGetters({
      dataDetail: 'acquisition/dataDetail'
    })
  },
  mounted(){
    if (this.dataDetail){
      this.realizationDisabled = {
        to: new Date(new Date(this.dataDetail.acquisition_farmer.date_contract).setHours(0, 0, 0, 0))
      }
    }
  },
  methods: {
    ...mapActions({
      fetchingUploadSpk: 'acquisition/fetchingUploadSpk',
      getDataDetail: 'acquisition/getDataDetail'
    }),
    handleUploadFile(data, target) {
      const file = data.target.files[0]
      if (file) {
        this.formData = {
          ...this.formData,
          [target]: {
            data: file,
            name: file.name
          }
        }
      }
    },
    handleSubmit() {
      this.$validator.validateAll().then(async (success) => {
        if (success) {
          if (this.$validator.errors.any()) return
          this.isLoading = true
          const formData = new FormData()
          formData.append('document_spk_file', this.formData.document_spk_file.data)
          formData.append('date_realization_spk', dateString(this.formData.date_realization_spk))
          formData.append('document_number',this.formData.document_number)
          formData.append('acquisition_id',this.formData.acquisition_id)
          await this.fetchingUploadSpk(formData)
          this.isLoading = false
          setTimeout(() => {
            window.location.reload()
          }, 700)
        }
      })
    }
  }
}
</script>

<style scoped>
  .icon-upload {
    padding: 8px;
  }

  .uk-form-icon {
    z-index: 1;
  }

  strong {
    font-weight: 600;
    font-size: 15px;
    line-height: 150%;
    color: #1F2E28
  }
</style>
