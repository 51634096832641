<template>
  <div
    id="modal-reason-retire"
    uk-modal
    esc-close="false"
    bg-close="false"
    container="#container-modal-reason"
  >
    <div class="uk-modal-dialog uk-modal-body uk-width-1-3">
      <div
        class="uk-child-width-expand@s uk-grid-small"
        uk-grid
      >
        <div class="uk-width-1-1">
          <h2
            class="label-green uktext-bold"
          >
            <img
              v-lazy="`${images}/icon/warning.svg`"
              alt="icon edit"
              class="uk-margin-small-right"
            >
            Pengunduran Diri
          </h2>
          <p style="font-size:18px">
            Masukkan Alasan Mitra Mengundurkan Diri
          </p>
        </div>
        <div class="uk-width-1-1 uk-text-center uk-margin-top">
          <div
            class="uk-width-1-1 "
            uk-form-custom
          >
            <input
              v-model="reason"
              class="uk-input"
              :class="{'uk-form-danger': reasonError}"
              type="text"
              name="reason"
              placeholder="Masukkan Alasan"
            >
          </div>
          <div
            v-if="reasonError"
            class="uk-text-small uk-text-danger"
          >
            {{ reasonError }}
          </div>
        </div>
        <div class="uk-width-1-1 uk-text-center uk-margin-top">
          <button
            class="uk-modal-close uk-button uk-button-default uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
            type="button"
            :disabled="isLoading"
          >
            Batal
          </button>
          <button
            class="uk-button uk-button-primary uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
            type="button"
            :disabled="isLoading"
            @click="handleSubmitRetire"
          >
            <span
              v-if="isLoading"
              uk-spinner
              class="button-spinner uk-text-muted"
            />
            Ya, simpan
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PREFIX_IMAGE } from '@/utils/constant'
import { notificationSuccess } from '@/utils/notification'
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      images: PREFIX_IMAGE,
      reason: '',
      reasonError: '',
      isLoading: false
    }
  },
  computed: {
    ...mapGetters({
      retireMessage: 'acquisition/retireMessage'
    })
  },
  watch:{
    reason(){
      if(this.reason){
        this.reasonError = ''
      }
    }
  },
  methods: {
    ...mapActions({
      retire: 'acquisition/retire'
    }),
    async handleSubmitRetire() {
      if(this.reason){
        this.isLoading = true
        let data = {
          id: this.$route.params.id,
          reason: this.reason
        }
        await this.retire(data)
        if(this.retireMessage.toLowerCase() == 'ok'){
          notificationSuccess('Proses Mengundurkan Diri Mitra Berhasil')
          this.isLoading = false
          this.$router.go(0)
        } else {
          this.isLoading = false
        }
      } else {
        this.reasonError = 'Masukkan Alasan!'
      }
    }
  }
}
</script>
