<template>
  <MainCard :data-header="dataHeader">
    <div class="uk-margin-top uk-margin-bottom">
      <div class="uk-card uk-card-default uk-padding-small kandang-card">
        <loading-card v-if="isLoading" />
        <template v-else>
          <form-mitra :is-detail="isDetail" />
          <modal-out-confirm :target-path="targetPath" />
        </template>
      </div>
    </div>

    <!-- Card section progress partner -->
    <template v-if="pathName.includes('detail')">
      <progress-partner :data-detail="dataDetail" />
    </template>
  </MainCard>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import getHeaderTitle from '@/utils/header-title'
import { capitalize, encodeStatusMitra } from '@/utils/formater'
import ModalOutConfirm from '@/components/globals/modal/BeforeLeave'
import LoadingCard from '@/components/globals/loading'
import MainCard from '@/components/globals/card'
import FormMitra from './form'
import ProgressPartner from './ProgressPartner'
import { isCanAccess } from '@/utils/auth'

export default {
  components: {
    MainCard,
    FormMitra,
    ProgressPartner,
    ModalOutConfirm,
    LoadingCard
  },
  data() {
    return {
      pathName: this.$route.fullPath,
      targetPath: '',
      listCardFailed: [1, 3],
      isDetail: false,
      statusPartner: false,
      isLoading: true,
      dataHeader: {
        title: '',
        isBreadcrumb: true,
        breadcrumb: [
          {
            link: '/admin/akuisisi',
            title: 'Akuisisi'
          },
          {
            link: null,
            title: 'Tambah Mitra'
          }
        ]
      }
    }
  },
  computed : {
    ...mapGetters({
      dataDetail: 'acquisition/dataDetail'
    })
  },
  watch: {
    async dataDetail() {
      if (this.dataDetail) {
        await this.getDataDetailStatus(this.$route.params.id)
        this.statusPartner = !this.dataDetail.status || this.listCardFailed.includes(this.dataDetail.status_acquisition)
        if(this.dataDetail.status_acquisition == encodeStatusMitra(12) || !isCanAccess('edit', 'Akuisisi')){
          this.setDisabledEdit(true)
        }
      }
    }
  },
  beforeRouteLeave (to) {
    this.targetPath = to.fullPath
    if (this.pathName.includes('detail')) {
      return window.location = this.targetPath
    } return window.UIkit.modal('#modal-before-leave').show()
  },
  async mounted() {
    await Promise.all([
      this.handleDataFormRequirement(),
      this.handleBreadcrumb(),
      this.getCity({is_all: true})
    ])
    this.dataHeader.title = this.dataDetail ? `${getHeaderTitle(this.pathName)} "${this.dataDetail.name}"` : 'Tambah Mitra'
    this.isLoading = false
  },
  methods: {
    ...mapMutations({
      setDisabledEdit: "acquisition/SET_DISABLED_EDIT"
    }),
    ...mapActions({
      getCageCategory: 'cageCategory/getCageCategory',
      getUser: 'user/getUser',
      getDataDetail: 'acquisition/getDataDetail',
      getDataDetailStatus: 'acquisition/getDataDetailStatus',
      getCity: 'city/getCity'
    }),
    async handleFethingDetail() {
      await this.getDataDetail(this.$route.params.id)
    },
    handleBreadcrumb() {
      const titlePage = this.pathName.split('/')[3]
      const capitalizeTitlePage = capitalize(titlePage)
      this.dataHeader.breadcrumb[1].title = 
        capitalizeTitlePage == 'Add' ? "Tambah" + ' Mitra' : capitalizeTitlePage + ' Mitra'
      this.isDetail = titlePage == 'detail'
    },
    async handleDataFormRequirement() {
      await this.getCageCategory({
        status: true,
        is_all: true
      })
      await this.getUser({
        status: true,
        role: 'PPL',
        is_all: true
      })
      if (!this.pathName.includes('/add')) {
        await this.handleFethingDetail()
      }
    }
  }
}
</script>
