<template>
  <div>
    <div>
      <div
        class="uk-child-width-expand@s uk-grid-small"
        uk-grid
      >
        <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div
              v-if="!pathName.includes('add')"
              class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical"
            >
              Kode Akuisisi
            </div>
            <div
              v-if="!pathName.includes('add')"
              class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl"
            >
              <input
                v-model="formData.acquisition_code"
                v-validate="{ required: true, regex: '^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$' }"
                class="uk-input"
                type="text"
                name="acquisition-code"
                placeholder="-"
                disabled
                :class="{'uk-form-danger': errors.has('acquisition-code')}"
              >
              <div
                v-show="errors.has('acquisition-code')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('acquisition-code') }}
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Nama Mitra<span class="uk-text-danger">*</span>
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="formData.name"
                v-validate="{ required: true, regex: '^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$' }"
                maxlength="50"
                class="uk-input"
                type="text"
                name="partner-name"
                placeholder="Masukkan Nama"
                :disabled="isDetail"
                :class="{'uk-form-danger': errors.has('partner-name')}"
              >
              <div
                v-show="errors.has('partner-name')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('partner-name') }}
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Email
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="formData.email"
                v-validate="'email'"
                class="uk-input"
                type="email"
                name="email"
                placeholder="Masukkan Email"
                :disabled="isDetail"
                :class="{'uk-form-danger': errors.has('email')}"
              >
              <div
                v-show="errors.has('email')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('email') }}
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Alamat Sesuai KTP<span class="uk-text-danger">*</span>
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <textarea
                v-model="formData.address"
                v-validate="{ required: true }"
                name="address"
                placeholder="Masukkan Alamat Sesuai KTP"
                rows="5"
                :disabled="isDetail"
                :class="{'uk-form-danger': errors.has('address'), 'uk-textarea': true}"
              />
              <div
                v-show="errors.has('address')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('address') }}
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Kota/Kabupaten<span class="uk-text-danger">*</span>
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <multiselect
                v-if="!pathName.includes('detail')"
                v-model="formData.city_id"
                v-validate="'required'"
                label="city"
                name="city_id"
                track-by="city"
                placeholder="Pilih..."
                open-direction="bottom"
                :options="dataCities"
                :searchable="true"
                :close-on-select="true"
                :max-height="500"
                :show-no-results="true"
                :disabled="isDetail"
              >
                <span slot="noResult">Oops! Data tidak ditemukan.</span>
              </multiselect>
              <input
                v-else-if="formData.city_id"
                v-model="formData.city_id.city"
                class="uk-input"
                disabled
              >
              <input
                v-else
                value="-"
                class="uk-input"
                disabled
              >
              <div
                v-show="errors.has('city_id')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('city_id') }}
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Alamat Lahan<span class="uk-text-danger">*</span>
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <textarea
                v-model="formData.land_address"
                v-validate="{ required: true }"
                name="land_address"
                placeholder="Masukkan Alamat Lahan"
                rows="5"
                :disabled="isDetail"
                :class="{'uk-form-danger': errors.has('land_address'), 'uk-textarea': true}"
              />
              <div
                v-show="errors.has('land_address')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('land_address') }}
              </div>
            </div>
          </div>
        </div>
        <div 
          class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl"
          :class="{'uk-margin-small-top':pathName.includes('add')}"
        >
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Nomor Handphone<span class="uk-text-danger">*</span>
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <div class="uk-inline">
                <span class="uk-form-icon">+62 </span>
                <input
                  v-model="formData.phone_number"
                  v-validate="{ required: true, regex: '^([0-9]+)$', min: '10', max: '13' }"
                  type="number"
                  name="phone_number"
                  placeholder="Masukkan Nomor Handphone"
                  :disabled="isDetail"
                  :class="{'uk-form-danger': errors.has('phone_number'), 'uk-input': true}"
                >
              </div>
              <div
                v-show="errors.has('phone_number')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('phone_number') }}
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div :class="{'uk-width-1-1': true, 'uk-width-1-1@s': true, 'uk-width-1-1@m': true, 'uk-width-2-5@l': true, 'uk-width-2-5@xl': true, 'uk-margin-auto-vertical': !hasPlanLocation}">
              Rencana Lokasi Lahan<span class="uk-text-danger">*</span>
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <multiselect
                v-if="!pathName.includes('detail')"
                v-model="formData.land_location_plan" 
                v-validate="'required'"
                label="name"
                name="land_location_plan"
                track-by="name"
                placeholder="Pilih..."
                open-direction="bottom"
                :options="listLocationPlan"
                :searchable="true"
                :close-on-select="true"
                :show-no-results="true"
                :disabled="isDetail"
                @select="(value) => handleNewField(value, 'land_location_plan')"
              >
                <span slot="noResult">Oops! Data tidak ditemukan.</span>
              </multiselect>
              <input
                v-else-if="formData.land_location_plan"
                v-model="formData.land_location_plan.name"
                class="uk-input"
                disabled
              >
              <input
                v-else
                value="-"
                class="uk-input"
                disabled
              >
              <div
                v-show="errors.has('land_location_plan')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('land_location_plan') }}
              </div>
              <!-- This field will be open if land location plan is pick 'Lainnya' -->
              <template v-if="hasPlanLocation">
                <div class="uk-width-1-1 uk-margin-small-top">
                  <input
                    v-model="formData.other_land_location_plan"
                    v-validate="'required'"
                    class="uk-input"
                    type="text"
                    name="other_land_location_plan"
                    placeholder="Lokasi Lahan Lainnya"
                    :disabled="isDetail"
                    :class="{'uk-form-danger': errors.has('other_land_location_plan')}"
                  >
                  <div
                    v-show="errors.has('other_land_location_plan')"
                    class="uk-text-small uk-text-danger"
                  >
                    {{ errors.first('other_land_location_plan') }}
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div :class="{'uk-width-1-1': true, 'uk-width-1-1@s': true, 'uk-width-1-1@m': true, 'uk-width-2-5@l': true, 'uk-width-2-5@xl': true, 'uk-margin-auto-vertical': !hasOtherInformation}">
              Info PARANJE<span class="uk-text-danger">*</span>
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <multiselect 
                v-if="!pathName.includes('detail')"
                v-model="formData.info" 
                v-validate="'required'"
                label="name" 
                name="info" 
                track-by="name" 
                placeholder="Pilih..." 
                open-direction="bottom" 
                :options="listInformation"
                :searchable="true"
                :close-on-select="true" 
                :max-height="500"
                :show-no-results="true"
                :disabled="isDetail"
                @select="(value) => handleNewField(value, 'info_paranje')"
              >
                <span slot="noResult">Oops! Data tidak ditemukan.</span>
              </multiselect>
              <input
                v-else-if="formData.info"
                v-model="formData.info.name"
                class="uk-input"
                disabled
              >
              <input
                v-else
                value="-"
                class="uk-input"
                disabled
              >
              <div
                v-show="errors.has('info')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('info') }}
              </div>
              <!-- This field will be open if other info is pick 'Lainnya' -->
              <template v-if="hasOtherInformation">
                <div class="uk-width-1-1 uk-margin-small-top">
                  <input
                    v-model="formData.other_info"
                    v-validate="'required'"
                    class="uk-input"
                    type="text"
                    name="other_info"
                    placeholder="Info PARANJE Lainnya"
                    :disabled="isDetail"
                    :class="{'uk-form-danger': errors.has('other_info')}"
                  >
                  <div
                    v-show="errors.has('other_info')"
                    class="uk-text-small uk-text-danger"
                  >
                    {{ errors.first('other_info') }}
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div :class="{'uk-width-1-1': true, 'uk-width-1-1@s': true, 'uk-width-1-1@m': true, 'uk-width-2-5@l': true, 'uk-width-2-5@xl': true, 'uk-margin-auto-vertical': !hasOtherProfession}">
              Profesi<span class="uk-text-danger">*</span>
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <multiselect 
                v-if="!pathName.includes('detail')"
                v-model="formData.profession"
                v-validate="'required'"
                label="name"
                name="profession"
                track-by="name"
                placeholder="Pilih..."
                open-direction="bottom"
                :options="listProfession"
                :searchable="true"
                :close-on-select="true"
                :max-height="500"
                :show-no-results="true"
                :disabled="isDetail"
                @select="(value) => handleNewField(value, 'profession')"
              >
                <span slot="noResult">Oops! Data tidak ditemukan.</span>
              </multiselect>
              <input
                v-else-if="formData.profession"
                v-model="formData.profession.name"
                class="uk-input"
                disabled
              >
              <input
                v-else
                value="-"
                class="uk-input"
                disabled
              >
              <div
                v-show="errors.has('profession')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('profession') }}
              </div>
              <!-- This field will be open if profession is pick 'Lainnya' -->
              <template v-if="hasOtherProfession">
                <div class="uk-width-1-1 uk-margin-small-top">
                  <input
                    v-model="formData.other_profession"
                    v-validate="'required'"
                    class="uk-input"
                    type="text"
                    name="other_profession"
                    placeholder="Profesi Lainnya"
                    :disabled="isDetail"
                    :class="{'uk-form-danger': errors.has('other_profession')}"
                  >
                  <div
                    v-show="errors.has('other_profession')"
                    class="uk-text-small uk-text-danger"
                  >
                    {{ errors.first('other_profession') }}
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Pernah Bergabung Mitra Lain?<span class="uk-text-danger">*</span>
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <div
                class="uk-margin uk-grid-small uk-child-width-auto uk-grid"
                @change="(value) => handleNewField(value, 'partner')"
              >
                <label>
                  <input
                    v-model="formData.join_other_partner"
                    v-validate="{ required: true }"
                    class="uk-radio"
                    type="radio"
                    name="join_other_partner"
                    value="1"
                    :disabled="isDetail"
                  > Pernah
                </label>
                <label>
                  <input
                    v-model="formData.join_other_partner"
                    v-validate="{ required: true }"
                    class="uk-radio"
                    type="radio"
                    name="join_other_partner"
                    value="0"
                    :disabled="isDetail"
                  > Tidak Pernah
                </label>
                <div
                  v-show="errors.has('join_other_partner')"
                  class="uk-text-small uk-text-danger"
                >
                  {{ errors.first('join_other_partner') }}
                </div>
              </div>
              <!-- This field will be open if join other partner name is pick 'Pernah' -->
              <template v-if="hasJoinPartner">
                <div class="uk-width-1-1">
                  <input
                    v-model="formData.other_partner_name"
                    v-validate="'required'"
                    class="uk-input"
                    type="text"
                    name="other_partner_name"
                    placeholder="Nama Kemitraan"
                    :disabled="isDetail"
                    :class="{'uk-form-danger': errors.has('other_partner_name')}"
                  >
                  <div
                    v-show="errors.has('other_partner_name')"
                    class="uk-text-small uk-text-danger"
                  >
                    {{ errors.first('other_partner_name') }}
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Tipe Kandang<span class="uk-text-danger">*</span>
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <multiselect
                v-if="!pathName.includes('detail')"
                v-model="formData.cage_category_id"
                v-validate="'required'"
                label="name"
                name="cage_category_id"
                track-by="name"
                placeholder="Pilih..."
                open-direction="bottom"
                :options="listCageType.data"
                :loading="listCageType.isLoading"
                :searchable="true"
                :close-on-select="true"
                :max-height="500"
                :show-no-results="true"
                :disabled="!pathName.includes('add')"
              >
                <span slot="noResult">Oops! Data tidak ditemukan.</span>
              </multiselect>
              <input
                v-else-if="formData.cage_category_id"
                v-model="formData.cage_category_id.name"
                class="uk-input"
                disabled
              >
              <input
                v-else
                value="-"
                class="uk-input"
                disabled
              >
              <div
                v-show="errors.has('cage_category_id')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('cage_category_id') }}
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Nama PPL
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <multiselect
                v-if="!pathName.includes('detail')"
                v-model="formData.field_supervisor_id" 
                label="name" 
                name="ppl_name" 
                track-by="name" 
                placeholder="Pilih..." 
                open-direction="bottom"
                :options="listPplUser.data"
                :loading="listPplUser.isLoading"
                :searchable="true"
                :close-on-select="true" 
                :max-height="500"
                :show-no-results="true"
                :disabled="isDetail"
                @search-change="handleSearch"
              >
                <span slot="noResult">Oops! Data tidak ditemukan.</span>
              </multiselect>
              <input
                v-else-if="formData.field_supervisor_id"
                v-model="formData.field_supervisor_id.name"
                class="uk-input"
                disabled
              >
              <input
                v-else
                value="-"
                class="uk-input"
                disabled
              >
              <div
                v-show="errors.has('ppl_name')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('ppl_name') }}
              </div>
            </div>
          </div>
          <div
            v-if="showSpkField"
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Nomor SPK
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="formData.document_number"
                class="uk-input"
                type="text"
                name="document_number"
                placeholder="-"
                :disabled="isDetail"
              >
            </div>
          </div>
        </div>
      </div>
      <div
        class="uk-child-width-expand@s uk-grid-small"
        uk-grid
      >
        <div class="uk-width-1-1 uk-text-right">
          <button
            v-if="!pathName.includes('detail')"
            class="uk-button uk-button-danger uk-width-expand uk-width-auto@s"
            type="button"
            @click="handleCancelled"
          >
            <img
              v-lazy="`${images}/icon/close.svg`"
              alt="icon close"
              class="uk-margin-small-right"
            >
            Batal
          </button>
          <button
            v-if="!pathName.includes('detail')"
            class="uk-button uk-button-primary uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
            type="button"
            @click="handleSubmit"
          >
            <img
              v-lazy="`${images}/icon/save.svg`"
              alt="icon save"
              class="uk-margin-small-right"
            >
            Simpan
          </button>
          <button
            v-if="pathName.includes('detail') && !cantPressRetire.includes(dataDetail.status_acquisition)"
            class="uk-button uk-button-primary uk-button-retire uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
            type="button"
            @click="handleRetire"
          >
            <img
              v-lazy="`${images}/icon/warning-white.svg`"
              alt="icon edit"
              class="uk-margin-small-right"
            >
            Mengundurkan Diri
          </button>
          <button
            v-if="pathName.includes('detail') && dataDetail.status_acquisition != encodeStatusMitra(1)"
            class="uk-button uk-button-primary uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
            type="button"
            :disabled="disabledEdit"
            @click="handleSubmit"
          >
            <img
              v-if="!disabledEdit"
              v-lazy="`${images}/icon/edit.svg`"
              alt="icon edit"
              class="uk-margin-small-right"
            >
            Ubah Data Mitra
          </button>
        </div>
      </div>
      <!-- Response for modal confirm close page  -->
      <modal-out-confirm :target-path="targetPath" />
      <!-- Response for modal confirm save detail page  -->
      <modal-save-confirm :props-create="propsPayload" />
      <modal-retire />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ModalOutConfirm from '@/components/globals/modal/OutConfirm'
import ModalSaveConfirm from '@/components/globals/modal/SaveConfirm'
import ModalRetire from './ModalResign'
import { PREFIX_IMAGE, DATA_LOCATION_PLAN, DATA_INFO_PARANJE, DATA_PROFESSION } from '@/utils/constant'
import { encodeStatusMitra } from '@/utils/formater'

export default {
  components: {
    ModalOutConfirm,
    ModalSaveConfirm,
    ModalRetire
  },
  props: {
    isDetail: {
      required: true,
      type: Boolean
    }
  },
  data() {
    return {
      showSpkField: false,
      pathName: this.$route.fullPath,
      images: PREFIX_IMAGE,
      targetPath: '',
      formData: {
      },
      listLocationPlan: DATA_LOCATION_PLAN,
      listInformation: DATA_INFO_PARANJE,
      listProfession: DATA_PROFESSION,
      listCageType: {
        data: [],
        isLoading: true
      },
      listPplUser: {
        data: [],
        isLoading: true
      },
      hasJoinPartner: false,
      hasPlanLocation: false,
      hasOtherInformation: false,
      hasOtherProfession: false,
      debounce: null,
      propsPayload: {},
      dataCities: [],
      disabledEdit: false,
      cantPressRetire:[
        encodeStatusMitra(0),
        encodeStatusMitra(1),
        encodeStatusMitra(2),
        encodeStatusMitra(3),
        encodeStatusMitra(12)
      ]
    }
  },
  computed : {
    ...mapGetters({
      cageCategorys: 'cageCategory/cageCategorys',
      users: 'user/users',
      dataCreate: 'acquisition/dataCreate',
      dataUpdate: 'acquisition/dataUpdate',
      dataDetail: 'acquisition/dataDetail',
      cities: 'city/cities',
      getDisabledEdit: 'acquisition/disabledEdit'
    })
  },
  watch:{
    dataDetail(){
      if(this.dataDetail){
        this.disabledEdit = this.getDisabledEdit
        if(this.dataDetail.status_acquisition == encodeStatusMitra(8)){
          this.showSpkField= true
        }
      }
    }
  },
  async mounted() {
    this.listCageType = {
      data: this.cageCategorys ? this.cageCategorys : [],
      isLoading: false
    }
    this.listPplUser = {
      data: this.users ? this.users : [],
      isLoading: false
    }
    await this.handleDataDetail()
    this.dataCities = this.cities ? this.cities : []
  },
  methods: {
    ...mapActions({
      getUser: 'user/getUser',
      createAcquisition: 'acquisition/createAcquisition',
      updateAcquisition: 'acquisition/updateAcquisition'
    }),
    handleDataDetail() {
      if (this.dataDetail) {
        this.hasJoinPartner = this.dataDetail.join_other_partner == 1
        this.hasPlanLocation = this.dataDetail.land_location_plan == 'Lainnya'
        this.hasOtherInformation = this.dataDetail.info == 'Lainnya'
        this.hasOtherProfession = this.dataDetail.profession == 'Lainnya'
        this.listCageType.isLoading = false
        this.listPplUser.isLoading = false
        this.formData = {
          ...this.dataDetail,
          document_number: this.dataDetail.acquisition_info.document_number,
          land_location_plan: this.listLocationPlan.find(item => item.name == this.dataDetail.land_location_plan),
          cage_category_id: this.cageCategorys ? this.cageCategorys.find(item => item.id == this.dataDetail.cage_category_id) : null,
          field_supervisor_id: this.users ? this.users.find(item => item.name == this.dataDetail.field_supervisor_id) : null,
          info: this.listInformation.find(item => item.name == this.dataDetail.info),
          profession: this.listProfession.find(item => item.name == this.dataDetail.profession),
          city_id: this.cities.find(item => item.id == this.dataDetail.city_id),
          join_other_partner: this.pathName.includes('edit') ? (this.dataDetail.join_other_partner) : this.dataDetail.join_other_partner
        }
      }
    },
    handleNewField(value, field) {
      if (field == 'partner'){
        if (value.target.value == '0') return this.hasJoinPartner = false
        return this.hasJoinPartner = true
      } else if (field == 'land_location_plan') {
        if (value.name.includes('Lainnya')) return this.hasPlanLocation = true
        return this.hasPlanLocation = false
      } else if (field == 'info_paranje') {
        if (value.name.includes('Lainnya')) return this.hasOtherInformation = true
        return this.hasOtherInformation = false
      } else {
        if (value.name.includes('Lainnya')) return this.hasOtherProfession = true
        return this.hasOtherProfession = false 
      }
    },
    handleCancelled() {
      this.targetPath = '/admin/akuisisi'
      window.UIkit.modal('#modal-out-confirm').show()
    },
    handleSubmit() {
      if (this.pathName.includes('/add')) {
        this.$validator.validateAll().then((success) => {
          if (success) {
            if (this.$validator.errors.any()) return
            this.propsPayload = {
              function: this.createAcquisition,
              dataGetters: 'acquisition/dataCreate',
              message: 'Tambah Akuisisi Berhasil',
              nextPath: '/admin/akuisisi',
              payload: {
                ...this.formData,
                cage_category_id: this.formData.cage_category_id.id,
                field_supervisor_id: this.formData.field_supervisor_id ? this.formData.field_supervisor_id.id : '',
                info: this.formData.info.name,
                join_other_partner: parseInt(this.formData.join_other_partner),
                city_id: this.formData.city_id.id,
                land_location_plan: this.formData.land_location_plan.name,
                profession: this.formData.profession.name
              }
            }
            window.UIkit.modal('#modal-save-confirm').show()
          }
        })
      } else if (this.pathName.includes('/edit')) {
        this.$validator.validateAll().then((success) => {
          if (success) {
            if (this.$validator.errors.any()) return
            this.propsPayload = {
              function: this.updateAcquisition,
              dataGetters: 'acquisition/dataUpdate',
              message: 'Update Akuisisi Berhasil',
              nextPath: '/admin/akuisisi',
              payload: {
                ...this.formData,
                cage_category_id: this.formData.cage_category_id.id,
                field_supervisor_id: this.formData.field_supervisor_id ? this.formData.field_supervisor_id.id : '',
                info: this.formData.info.name,
                city_id: this.formData.city_id.id,
                land_location_plan: this.formData.land_location_plan.name,
                profession: this.formData.profession.name,
                join_other_partner: parseInt(this.formData.join_other_partner)
              }
            }
            window.UIkit.modal('#modal-save-confirm').show()
          }
        })
      } else {
        this.$router.push(`/admin/akuisisi/edit/${this.formData.id}`)
      }
    },
    handleSearch(value) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.getUser({
          status: true,
          role: 'PPL',
          is_all: true,
          name: value
        })
      }, 600)
    },
    encodeStatusMitra(number){
      return encodeStatusMitra(number)
    },
    handleRetire(){
      window.UIkit.modal('#modal-reason-retire').show()
    }
  }
}
</script>

<style scoped>
  .uk-button-retire{
    background-color: #ED5E5E !important;
    color: #fff !important;
  }
  .uk-radio:checked {
    background-color: #025231;
  }
</style>
