<template>
  <div
    v-if="!isLoading" 
    class="uk-margin-bottom"
  >
    <ul
      id="progress-tab"
      ref="progressPartnerTabs"
      class="uk-margin-remove progress-tab"
      data-uk-tab="{connect:'#progress-partner'}"
      uk-tab
    >
      <li class="selector-tab">
        <a href="">Status Mitra</a> 
      </li>
      <li
        v-show="payloadDetailMaster.acquisition_info.id"
        class="selector-tab"
      >
        <a href="#">Data Survei</a>
      </li>
      <li
        v-if="payloadDetailMaster.acquisition_verification.acquisition_id"
        class="selector-tab"
      >
        <a href="#">Dokumen Pendukung</a>
      </li>
      <li
        v-if="statusSupporting.includes(payloadDetail.status_acquisition)"
        class="selector-tab"
      >
        <a href="#">Sarana Pendukung</a>
      </li>
      <li
        v-if="statusInstallmentAmount.includes(payloadDetail.status_acquisition)"
        class="selector-tab"
      >
        <a href="#">Dana Jaminan</a>
      </li>
    </ul>

    <ul
      id="progress-partner"
      class="uk-switcher uk-padding-remove"
    >
      <li>
        <status-mitra />
      </li>
      <li>
        <mitra-potensial />
      </li>
      <li>
        <submit-and-verification />
      </li>
      <li v-if="statusSupporting.includes(payloadDetail.status_acquisition)">
        <supporting-facilities :data-detail-master="payloadDetailMaster" />
      </li>
      <li v-if="isStaging || statusInstallmentAmount.includes(payloadDetail.status_acquisition)">
        <installment-amount />
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import StatusMitra from './Component/StatusMitra'
import MitraPotensial from './Component/mitra-potensial'
import SubmitAndVerification from './Component/submit-and-verification'
import SupportingFacilities from './Component/SupportingFacilities'
import InstallmentAmount from './Component/installment-amount'
import { encodeStatusMitra } from '@/utils/formater'
export default {
  components: {
    StatusMitra,
    MitraPotensial,
    SubmitAndVerification,
    SupportingFacilities,
    InstallmentAmount
  },
  data() {
    return {
      isStaging: process.env.VUE_APP_ENV == 'staging',
      isLoading: true,
      payloadDetail: {},
      payloadDetailMaster: {},
      default_date: "0001-01-01T00:00:00Z",
      statusMitraPotensial: [
        encodeStatusMitra(4), 
        encodeStatusMitra(5), 
        encodeStatusMitra(6), 
        encodeStatusMitra(8), 
        encodeStatusMitra(9), 
        encodeStatusMitra(10),
        encodeStatusMitra(11),
        encodeStatusMitra(12)
      ],
      statusMitraSubmit: [
        encodeStatusMitra(5), 
        encodeStatusMitra(6), 
        encodeStatusMitra(8), 
        encodeStatusMitra(9), 
        encodeStatusMitra(10),
        encodeStatusMitra(11)
      ],
      statusInstallmentAmount: [
        encodeStatusMitra(8), 
        encodeStatusMitra(9), 
        encodeStatusMitra(10),
        encodeStatusMitra(11)
      ],
      statusSupporting: [
        encodeStatusMitra(8), 
        encodeStatusMitra(9), 
        encodeStatusMitra(10),
        encodeStatusMitra(11)
      ]
    }
  },
  computed : {
    ...mapGetters({
      dataDetail: 'acquisition/dataDetail',
      dataDetailStatus: 'acquisition/dataDetailStatus'
    })
  },
  watch: {
    async dataDetail() {
      if (await this.dataDetail) {
        this.payloadDetailMaster = this.dataDetail
        this.isLoading = false
      }
    },
    async dataDetailStatus() {
      if (await this.dataDetailStatus) {
        this.payloadDetail = this.dataDetailStatus
      }
      if(this.dataDetail.acquisition_verification.id || this.dataDetailStatus.status_acquisition_potential.updated_at_potential != this.default_date){
        this.statusMitraSubmit.push(encodeStatusMitra(12))
      }
      if(this.dataDetail.acquisition_farmer.id || this.dataDetailStatus.status_acquisition_verification.updated_at_verification != this.default_date){
        this.statusInstallmentAmount.push(encodeStatusMitra(12))
        this.statusSupporting.push(encodeStatusMitra(12))
      }
    }
  }
}

</script>

<style scoped>

  .uk-tab::before {
    border: none;
  }

  .progress-tab > .uk-active {
    background: #FFFFFF !important;
    box-shadow: 0px 0px 8px -5px rgba(41, 45, 90, 0.1);
    border-radius: 16px 16px 0px 0px;
    margin-bottom: 0;
    padding-left: 0;
  }

  .selector-tab {
    background: #E0EBE6;
    box-shadow: 0px 0px 8px -5px rgba(41, 45, 90, 0.1);
    border-radius: 16px 16px 0px 0px;
    margin-bottom: 0;
    padding-left: 0;
    transition: all 0.5s ease
  }

  .selector-tab a {
    padding: 11px 32px 12px 32px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: #025231;
    text-align: center;
    border-color: transparent;
    text-transform: unset;
  }

  .progress-tab {
    width: 100%;
    overflow-x: auto;
    flex-wrap: nowrap;
  }

  @media screen and (min-width: 1200px) {
    .progress-tab {
      width: 100%;
      overflow-x: hidden;
      flex-wrap: wrap;
    }
  }

</style>
